import React, { ReactNode, useEffect, useRef, useState } from "react"
import styles from "./Map.module.scss"

interface MapProps extends google.maps.MapOptions {
    children: ReactNode
}

export const Map = (props: MapProps) => {
    const mapAreaRef = useRef<HTMLDivElement>(null)
    const [map, setMap] = useState<google.maps.Map>()

    useEffect(() => {
        if (mapAreaRef.current && !map) {
            setMap(
                new window.google.maps.Map(mapAreaRef.current, {
                    zoom: 8,
                    center: { lat: 34.54185453701149, lng: 136.36332227029098 },
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    heading: 0,
                    tilt: 0,
                    mapId: "84717a782015bb95",
                    disableDefaultUI: true,
                })
            )
        }
    }, [mapAreaRef, map])

    return (
        <div ref={mapAreaRef} className={styles.mapArea}>
            {React.Children.map(props.children, (child) => {
                if (React.isValidElement(child)) {
                    console.log(child)
                    return React.cloneElement(child, { map } as { map: google.maps.Map })
                }
            })}
        </div>
    )
}
