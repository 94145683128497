// サービス終了のためテストは省略

import React, { createContext, Dispatch, ReactNode, SetStateAction, useRef, useState } from "react"
import { HistoryOfVisit } from "@/repositories/historyOfVisitRepository"
import { tourList2025 } from "../data/tourList2025"

export type ApplicationContextValue2025 = Readonly<{
    histories: HistoryOfVisit[]
    setHistories: Dispatch<SetStateAction<HistoryOfVisit[]>>
    toursAreaRef: React.RefObject<HTMLDivElement>
    favoriteList: string[]
    setFavoriteList: React.Dispatch<React.SetStateAction<string[]>>
    stateOfJinjaFilter: (boolean | undefined)[]
    setStateOfJinjaFilter: React.Dispatch<React.SetStateAction<(boolean | undefined)[]>>
    singlePhotoViewMode: string | undefined
    setSinglePhotoViewMode: React.Dispatch<React.SetStateAction<string | undefined>>
    selectPictureIndex: number
    setSelectPictureIndex: React.Dispatch<React.SetStateAction<number>>
}>

export const ApplicationContext2025 = createContext<ApplicationContextValue2025 | null>(null)

type Props = Readonly<{ children: ReactNode }>
export const ApplicationContextProvider2025: React.FC<Props> = ({ children }) => {
    const historiesInitValue: HistoryOfVisit[] = []
    tourList2025.forEach((tour2025) => {
        tour2025.jinjaList.forEach((jinja) => {
            const historyString = localStorage.getItem(jinja.name)
            if (historyString) {
                const newHistoryOfVisit: HistoryOfVisit = {
                    jinjaName: jinja.name,
                    jinjaLastWord: jinja.lastWord,
                    visitDate: 0,
                    jinjaId: jinja.jinjaId,
                    historyOfVisitId: "unused",
                }
                historiesInitValue.push(newHistoryOfVisit)
            }
        })
    })
    const [histories, setHistories] = useState<HistoryOfVisit[]>(historiesInitValue)
    const toursAreaRef = useRef<HTMLDivElement>(null)
    const favoriteListString = localStorage.getItem("favoriteList")
    const [favoriteList, setFavoriteList] = useState<string[]>(
        favoriteListString ? JSON.parse(favoriteListString) : []
    )
    const [stateOfJinjaFilter, setStateOfJinjaFilter] = useState<(boolean | undefined)[]>([
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
    ])
    const [singlePhotoViewMode, setSinglePhotoViewMode] = useState<string | undefined>("")
    const [selectPictureIndex, setSelectPictureIndex] = useState<number>(0)

    return (
        <ApplicationContext2025.Provider
            value={{
                histories,
                setHistories,
                toursAreaRef,
                favoriteList,
                setFavoriteList,
                stateOfJinjaFilter,
                setStateOfJinjaFilter,
                singlePhotoViewMode,
                setSinglePhotoViewMode,
                selectPictureIndex,
                setSelectPictureIndex,
            }}
        >
            {children}
        </ApplicationContext2025.Provider>
    )
}
