import Swal from "sweetalert2"
import styles from "./swalFires.module.scss"
import errorIcon from "@/assets/errorIcon.svg"

export const serviceWorkerUpdatePopup = (
    updateSwRef: React.MutableRefObject<(() => void) | undefined>
) => {
    Swal.fire({
        html:
            `<img alt="errorIcon" src=${errorIcon} />` +
            `<h3>アプリを更新してください</h3>` +
            "<p>1月以降も掲載神社を引き続き<br/>ご覧いただけるようになります</p>" +
            `<button class=${styles.closeButton} id='serviceWorkerUpdatePopup_closeButton'>更新</button>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.serviceWorkerUpdatePopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("serviceWorkerUpdatePopup_closeButton")
            closeButton!.onclick = () => {
                updateSwRef?.current && updateSwRef!.current()
                Swal.close()
            }
        },
    })
}
