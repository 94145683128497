import { PaidGoogleMap } from "@/components/PaidGoogleMap"
import styles from "./MapPage.module.scss"
import { isIphone } from "@/functions/isIphone"
import { useContext, useEffect, useRef, useState } from "react"
import { Jinja } from "@/repositories/jinjaRepository"
import { useNavigate } from "react-router-dom"
import { BackIcon } from "@/components/icon/BackIcon"
import { CloseIcon } from "@/components/icon/CloseIcon"
import closeIcon2 from "../assets/generalIcons/closeIcon.svg"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { cloneDeep } from "lodash"
import detailArrowIcon from "@/assets/homePage/detailArrowIcon.svg"

export const MapPage = () => {
    const { stateOfJinjaFilterForMap, setStateOfJinjaFilterForMap, range, setRange } =
        useContext(ApplicationContext)!
    const [selectJinjaList, setSelectJinjaList] = useState<Jinja[]>([])
    const [isShowJinjaFilter, setIsShowJinjaFilter] = useState(false)

    const toastPopUpAreaRef = useRef<HTMLHRElement>(null)
    const touchPosition = useRef({
        startY: 0,
        endY: 0,
        startClientHeight: 0,
        toastPopUpAreaStartClassName: "",
        startYDiff: 0,
    })
    const selectJinjaListAreaRef = useRef<HTMLDivElement>(null)
    const rangeSliderRef = useRef<HTMLInputElement>(null)
    const prevSelectJinjaListLength = useRef(0)

    const navigate = useNavigate()

    const filterRadioButtonClick = (radioButtonIndex: number, state: boolean | undefined) => {
        setStateOfJinjaFilterForMap((prevState) => {
            const newState = cloneDeep(prevState)
            newState[radioButtonIndex] = state
            return newState
        })
    }

    const sliderChange = (range: string) => {
        if (range === "1") {
            rangeSliderRef.current!.className = `${styles.rangeSlider} ${styles.rangeSlider1}`
        } else if (range === "2") {
            rangeSliderRef.current!.className = `${styles.rangeSlider} ${styles.rangeSlider2}`
        } else if (range === "3") {
            rangeSliderRef.current!.className = `${styles.rangeSlider} ${styles.rangeSlider3}`
        } else if (range === "4") {
            rangeSliderRef.current!.className = `${styles.rangeSlider} ${styles.rangeSlider4}`
        } else if (range === "5") {
            rangeSliderRef.current!.className = `${styles.rangeSlider} ${styles.rangeSlider5}`
        } else if (range === "6") {
            rangeSliderRef.current!.className = `${styles.rangeSlider} ${styles.rangeSlider6}`
        } else if (range === "7") {
            rangeSliderRef.current!.className = `${styles.rangeSlider} ${styles.rangeSlider7}`
        } else {
            rangeSliderRef.current!.className = `${styles.rangeSlider} ${styles.rangeSlider8}`
        }

        setRange(Number(range))
    }

    const createLink = () => {
        const positionArray = selectJinjaList.map(
            (jinja) => `${jinja.parkingPosition.latitude},${jinja.parkingPosition.longitude}`
        )
        const destination = positionArray.pop()
        return `https://www.google.com/maps/dir/?api=1&destination=${destination}&waypoints=${positionArray.join("|")}`
    }

    useEffect(() => {
        if (selectJinjaList.length > prevSelectJinjaListLength.current) {
            if (selectJinjaListAreaRef.current !== null) {
                selectJinjaListAreaRef.current!.scrollTo({
                    top: selectJinjaListAreaRef.current!.scrollHeight,
                    behavior: "smooth",
                })
            }
        }
        prevSelectJinjaListLength.current = selectJinjaList.length
    }, [selectJinjaList, selectJinjaListAreaRef])

    useEffect(() => {
        if (rangeSliderRef.current) sliderChange(`${range}`)
    }, [isShowJinjaFilter])

    const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        touchPosition.current.startY = e.targetTouches[0].clientY
        touchPosition.current.endY = e.targetTouches[0].clientY
        touchPosition.current.startClientHeight = toastPopUpAreaRef.current!.clientHeight
        touchPosition.current.toastPopUpAreaStartClassName = toastPopUpAreaRef.current!.className
        touchPosition.current.startYDiff = 0
    }

    const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (touchPosition.current.toastPopUpAreaStartClassName.includes("defaultToTopAnime")) {
            if (touchPosition.current.startY < e.targetTouches[0].clientY) {
                if (
                    (selectJinjaListAreaRef.current?.scrollTop &&
                        selectJinjaListAreaRef.current!.scrollTop <= 0) ||
                    selectJinjaList.length === 0 ||
                    !selectJinjaListAreaRef.current!.scrollTop
                ) {
                    touchPosition.current.endY = e.targetTouches[0].clientY
                    toastPopUpAreaRef.current!.className = `${styles.toastPopupArea}`
                    toastPopUpAreaRef.current!.style.height = `${touchPosition.current.startClientHeight + touchPosition.current.startY - e.targetTouches[0].clientY - touchPosition.current.startYDiff}px`
                } else {
                    touchPosition.current.startYDiff =
                        touchPosition.current.startY - e.targetTouches[0].clientY
                }
            }
        } else if (
            touchPosition.current.toastPopUpAreaStartClassName.includes("defaultToBottomAnime")
        ) {
            if (touchPosition.current.startY > e.targetTouches[0].clientY) {
                touchPosition.current.endY = e.targetTouches[0].clientY
                toastPopUpAreaRef.current!.className = `${styles.toastPopupArea}`
                toastPopUpAreaRef.current!.style.height = `${touchPosition.current.startClientHeight + (touchPosition.current.startY - touchPosition.current.endY)}px`
            }
        } else {
            touchPosition.current.endY = e.targetTouches[0].clientY
            toastPopUpAreaRef.current!.className = `${styles.toastPopupArea}`
            toastPopUpAreaRef.current!.style.height = `${touchPosition.current.startClientHeight + (touchPosition.current.startY - touchPosition.current.endY)}px`
        }
    }

    const onTouchEnd = () => {
        //100px以上スワイプした時
        if (Math.abs(touchPosition.current.startY - touchPosition.current.endY) >= 100) {
            //トップから下にスワイプした時
            if (touchPosition.current.startClientHeight > 219) {
                toastPopUpAreaRef.current!.className = `${styles.toDefaultAnime} ${styles.toastPopupArea}`
                //ボトムから上にスワイプした時
            } else if (touchPosition.current.startClientHeight < 219) {
                toastPopUpAreaRef.current!.className = `${styles.toDefaultAnime} ${styles.toastPopupArea}`
                //デフォルト位置からスワイプした時
            } else {
                //上方向へのスワイプなら
                if (touchPosition.current.startY < touchPosition.current.endY) {
                    toastPopUpAreaRef.current!.className = `${styles.defaultToBottomAnime} ${styles.toastPopupArea}`
                    //下方向へのスワイプなら
                } else if (touchPosition.current.startY > touchPosition.current.endY) {
                    toastPopUpAreaRef.current!.className = `${styles.defaultToTopAnime} ${styles.toastPopupArea}`
                }
            }
            setTimeout(() => {
                selectJinjaListAreaRef.current!.scrollTo({
                    top: selectJinjaListAreaRef.current!.scrollTop,
                    behavior: "smooth",
                })
            }, 500)

            //100px未満スワイプした時
        } else {
            //トップから下にスワイプした時
            if (touchPosition.current.startClientHeight > 219) {
                toastPopUpAreaRef.current!.className = `${styles.defaultToTopAnime} ${styles.toastPopupArea}`
                //ボトムから上にスワイプした時
            } else if (touchPosition.current.startClientHeight < 219) {
                toastPopUpAreaRef.current!.className = `${styles.defaultToBottomAnime} ${styles.toastPopupArea}`
                //デフォルト位置からスワイプした時
            } else {
                toastPopUpAreaRef.current!.className = `${styles.toDefaultAnime} ${styles.toastPopupArea}`
            }
        }
    }

    return (
        <div
            className={`${styles.mapPageArea} ${isIphone() ? styles.iphoneMap : styles.androidMap}`}
        >
            <div className={styles.headerArea}>
                <button
                    className={styles.backButton}
                    onClick={() => {
                        navigate(-1)
                    }}
                >
                    <BackIcon />
                </button>
                <button
                    className={styles.filterButton}
                    onClick={() => {
                        setIsShowJinjaFilter(true)
                    }}
                >
                    絞り込み
                </button>
                {isShowJinjaFilter && (
                    <div className={styles.filterArea}>
                        <button
                            onClick={() => {
                                setIsShowJinjaFilter(false)
                            }}
                        >
                            <CloseIcon />
                        </button>
                        <label className={styles.rangeFilterArea}>
                            <p>現在地から</p>
                            <div>
                                <span>10km</span>
                                <span>20</span>
                                <span>30</span>
                                <span>40</span>
                                <span>50</span>
                                <span>60</span>
                                <span>70</span>
                                <span>全範囲</span>
                            </div>
                            <input
                                className={`${styles.rangeSlider}`}
                                ref={rangeSliderRef}
                                type="range"
                                min="1"
                                max="8"
                                step="1"
                                defaultValue={range}
                                onChange={(e) => {
                                    sliderChange(e.target.value)
                                }}
                            />
                        </label>
                        <div className={styles.jinjaFilterGrid}>
                            <p>お気に入り</p>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="favoriteGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[0] === undefined}
                                        onClick={() => {
                                            filterRadioButtonClick(0, undefined)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                設定なし
                            </label>
                            <label className={styles.twoColumn}>
                                <div>
                                    <input
                                        type="radio"
                                        name="favoriteGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[0] === true}
                                        onClick={() => {
                                            filterRadioButtonClick(0, true)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                登録済み
                            </label>
                            <p>参拝履歴</p>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="historyOfVisitGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[1] === undefined}
                                        onClick={() => {
                                            filterRadioButtonClick(1, undefined)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                設定なし
                            </label>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="historyOfVisitGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[1] === true}
                                        onClick={() => {
                                            filterRadioButtonClick(1, true)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                有り
                            </label>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="historyOfVisitGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[1] === false}
                                        onClick={() => {
                                            filterRadioButtonClick(1, false)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                無し
                            </label>
                            <p>御朱印</p>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="redSealGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[2] === undefined}
                                        onClick={() => {
                                            filterRadioButtonClick(2, undefined)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                設定なし
                            </label>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="redSealGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[2] === true}
                                        onClick={() => {
                                            filterRadioButtonClick(2, true)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                有り
                            </label>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="redSealGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[2] === false}
                                        onClick={() => {
                                            filterRadioButtonClick(2, false)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                無し
                            </label>
                            <p>お手洗い</p>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="toiletGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[3] === undefined}
                                        onClick={() => {
                                            filterRadioButtonClick(3, undefined)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                設定なし
                            </label>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="toiletGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[3] === true}
                                        onClick={() => {
                                            filterRadioButtonClick(3, true)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                有り
                            </label>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="toiletGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[3] === false}
                                        onClick={() => {
                                            filterRadioButtonClick(3, false)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                無し
                            </label>
                            <p>無料駐車場</p>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="parkingGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[4] === undefined}
                                        onClick={() => {
                                            filterRadioButtonClick(4, undefined)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                設定なし
                            </label>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="parkingGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[4] === true}
                                        onClick={() => {
                                            filterRadioButtonClick(4, true)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                有り
                            </label>
                            <label>
                                <div>
                                    <input
                                        type="radio"
                                        name="parkingGroup"
                                        defaultChecked={stateOfJinjaFilterForMap[4] === false}
                                        onClick={() => {
                                            filterRadioButtonClick(4, false)
                                        }}
                                    />
                                    <span className={styles.customRadio} />
                                </div>
                                無し
                            </label>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.mapArea}>
                <PaidGoogleMap
                    selectJinjaList={selectJinjaList}
                    setSelectJinjaList={setSelectJinjaList}
                />
                <div
                    ref={toastPopUpAreaRef}
                    className={styles.toastPopupArea}
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    <hr />
                    <div className={styles.selectJinjaListArea}>
                        {selectJinjaList.length === 0 ? (
                            <p>
                                参拝したい神社を選択してください
                                <br />
                                (複数選択可)
                            </p>
                        ) : (
                            <>
                                <button onClick={() => window.open(createLink(), "_blank")}>
                                    <img alt="detailArrowIcon" src={detailArrowIcon} />
                                    経路検索
                                </button>
                                <div ref={selectJinjaListAreaRef}>
                                    {selectJinjaList.map((jinja, index) => {
                                        return (
                                            <div key={index} className={styles.selectJinjaArea}>
                                                <img
                                                    src={`/jinjaImage/${jinja.jinjaId}.jpg`}
                                                    alt={jinja.name}
                                                />
                                                <p>
                                                    {jinja.name}
                                                    {jinja.lastWord}
                                                </p>
                                                <img
                                                    src={closeIcon2}
                                                    className={styles.closeIcon}
                                                    alt={"closeIcon"}
                                                    onClick={() => {
                                                        setSelectJinjaList((prevState) => {
                                                            return prevState.filter((state) => {
                                                                return (
                                                                    state.jinjaId !== jinja.jinjaId
                                                                )
                                                            })
                                                        })
                                                    }}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
