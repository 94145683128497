import { CropperModal } from "@/components/CropperModal"
import React, { useContext, useEffect, useRef, useState } from "react"
import megaphoneIcon from "@/assets/myPage/megaphoneIcon.svg"
import historyIcon from "@/assets/myPage/historyIcon.svg"
import termsOfServiceIcon from "@/assets/myPage/termsOfServiceIcon.svg"
import inquiryIcon from "@/assets/myPage/inquiryIcon.svg"
import badgeIcon from "@/assets/myPage/badgeIcon.svg"
import cameraIcon from "@/assets/myPage/cameraIcon.svg"
import penIcon from "@/assets/myPage/penIcon.svg"
import questionIcon from "@/assets/myPage/questionIcon.svg"
import notificationOffIcon from "@/assets/myPage/notificationOffIcon.svg"
import notificationOnIcon from "@/assets/myPage/notificationOnIcon.svg"
import { HistoryOfVisitWithToastPopup } from "@/components/HistoryOfVisits"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import {
    getFcmToken,
    getNickName,
    getUserIcon,
    updateAddHome,
    updateNickName,
    UserIcon,
} from "@/repositories/userRepository"
import styles from "./MyPage.module.scss"
import { TermsWithToastPopup } from "@/components/Terms"
import { BadgeCollectionViewWithToastPopup } from "@/components/BadgeCollection"
import { isIphone } from "@/functions/isIphone"
import { getPreSignedUrlObjectForGetUserIcon } from "@/repositories/awsRepository"
import { QuestionViewWithToastPopup } from "@/components/QuestionView"
import { notificationDeniedPopup } from "@/functions/swalPopups/notificationDeniedPopup"
import { notificationAlertPopup } from "@/functions/swalPopups/notificationAlertPopup"
import { notificationReceivePopup } from "@/functions/swalPopups/notificationReceivePopup"
import { getToken } from "firebase/messaging"
import { messaging } from "../firebaseConfig"
import { NewsViewWithToastPopup } from "@/components/NewsView"

export const MyPage = () => {
    const [isShowHistoryPopup, setIsShowHistoryPopup] = useState(false)
    const [isShowBadgePopup, setIsShowBadgePopup] = useState(false)
    const [isShowTermsPopup, setIsShowTermsPopup] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [dataURL, setDataURL] = useState("")
    const [nameText, setNameText] = useState<string>("")
    const [nameDBText, setNameDBText] = useState<string | null>(null)

    const {
        areaHeight,
        userIcon,
        setUserIcon,
        newsListLength,
        readNewsList,
        setIsShowQuestionPopup,
        isShowQuestionPopup,
        isShowNewsPopup,
        setIsShowNewsPopup,
    } = useContext(ApplicationContext)!

    const userImageInputRef = useRef<HTMLInputElement>(null)
    const userNameInputRef = useRef<HTMLInputElement>(null)
    const toggleNotificationDivRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        setNameText(nameDBText || "ゲスト")
    }, [nameDBText])

    useEffect(() => {
        sessionStorage.setItem("scrollPosition", "0")
        if (window.matchMedia("(display-mode: standalone)").matches) updateAddHome()
        getNickName().then((res) => setNameDBText(res.nickName ?? "ゲスト"))
        getUserIcon().then((it: UserIcon) => {
            if (it.isUserIcon) {
                getPreSignedUrlObjectForGetUserIcon().then((it) => {
                    setUserIcon(it.getUrl)
                })
            }
        })
        getFcmToken().then((token: string | null) => {
            if (
                Notification.permission === "granted" &&
                token !== null &&
                token !== "notificationOff"
            ) {
                if (toggleNotificationDivRef.current !== null) {
                    const imgElement = toggleNotificationDivRef.current
                        .children[0] as HTMLImageElement
                    const pElement = toggleNotificationDivRef.current
                        .children[1] as HTMLParagraphElement
                    imgElement.src = notificationOnIcon
                    imgElement.alt = "notificationOnIcon"
                    pElement.innerHTML = "通知オン"
                }
            }
        })
    }, [])

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader()
            reader.readAsDataURL(e.target.files[0])
            reader.onload = () => {
                if (reader.result) {
                    setDataURL(reader.result.toString() || "")
                    setIsOpenModal(true)
                }
            }
        }
    }

    const validateNickNameInput = (str: string) => {
        let len = 0
        for (let i = 0; i < str.length; i++) {
            str[i].match(/[ -~]/) ? (len += 1) : (len += 2)
        }
        len <= 16 && setNameText(str)
        return len
    }

    const toggleNotificationHandler = () => {
        if (toggleNotificationDivRef.current !== null) {
            const pElement = toggleNotificationDivRef.current.children[1] as HTMLParagraphElement
            if (pElement.innerHTML === "通知オン") {
                notificationDeniedPopup(toggleNotificationDivRef)
            } else {
                Notification.requestPermission().then((permission) => {
                    if (permission === "denied") {
                        notificationAlertPopup()
                    } else if (permission === "granted") {
                        notificationReceivePopup(toggleNotificationDivRef)
                    }
                })
            }
        }
    }

    return (
        <>
            <div
                className={`${styles.myPage}`}
                style={isIphone() ? { height: `${areaHeight}px` } : undefined}
            >
                <div className={styles.myPageImage}>
                    <div
                        className={styles.userIconArea}
                        onClick={() => {
                            userImageInputRef.current!.click()
                        }}
                    >
                        <img alt="userIcon" src={userIcon} />
                        <div>
                            <img alt="cameraIcon" src={cameraIcon} />
                        </div>
                        <input
                            hidden
                            accept="image/png, image/jpeg"
                            ref={userImageInputRef}
                            type="file"
                            onChange={onFileChange}
                        />
                    </div>
                    <div className={styles.nickNameArea} data-testid={"nicknameArea"}>
                        <p>{nameText.length > 0 ? nameText : nameDBText}</p>
                        <input
                            placeholder={nameDBText ? nameDBText : ""}
                            className={styles.nickNameInput}
                            type="text"
                            ref={userNameInputRef}
                            value={nameText}
                            onChange={(e) => validateNickNameInput(e.target.value)}
                            onBlur={() => {
                                if (nameText.length > 0) {
                                    updateNickName(nameText)
                                    setNameDBText(nameText)
                                } else {
                                    setNameText(nameDBText || "ゲスト")
                                }
                            }}
                        />
                        <div>
                            <img
                                alt="penIcon"
                                src={penIcon}
                                onClick={() => {
                                    userNameInputRef.current!.focus()
                                }}
                            />
                        </div>
                    </div>
                    <img
                        src={megaphoneIcon}
                        alt="megaphoneIcon"
                        onClick={() => {
                            setIsShowNewsPopup(true)
                        }}
                    />
                    {newsListLength > readNewsList.filter((value) => value).length && (
                        <div className={styles.newsIcon}></div>
                    )}
                </div>
                <div className={styles.listArea}>
                    <div>
                        <div onClick={() => setIsShowHistoryPopup(true)}>
                            <img alt="historyIcon" src={historyIcon} />
                            <p>参拝履歴</p>
                        </div>
                        <hr />
                        <div
                            onClick={() => {
                                setIsShowBadgePopup(true)
                            }}
                        >
                            <img alt="badgeIcon" src={badgeIcon} />
                            <p>集めたバッジ</p>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div
                                onClick={() => {
                                    setIsShowQuestionPopup(true)
                                }}
                            >
                                <img src={questionIcon} alt="questionIcon" />
                                <p>よくある質問</p>
                            </div>
                            <hr />
                            <div onClick={() => setIsShowTermsPopup(true)}>
                                <img alt="termsOfServiceIcon" src={termsOfServiceIcon} />
                                <p>利用規約</p>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <div ref={toggleNotificationDivRef} onClick={toggleNotificationHandler}>
                                <img alt="notificationOffIcon" src={notificationOffIcon} />
                                <p>通知オフ</p>
                            </div>
                            <hr />
                            <div
                                onClick={() => {
                                    const url =
                                        "https://forms.office.com/Pages/ResponsePage.aspx?id=XjPB0YL1qUK2_l4aFuubyKO8uAY1t1hOtz0Gb5mCBeFUOTVaMVpMT1JEVk9XQVRRODk5UVRLRFJXQy4u"
                                    window.open(url, "_blank")
                                }}
                            >
                                <img alt="inquiryIcon" src={inquiryIcon} />
                                <p>お問い合わせ</p>
                            </div>
                        </div>
                    </div>
                    {/*以下のdivはテストなし、cssなし*/}
                    {import.meta.env.VITE_DEV_ONLY === "true" && (
                        <div>
                            <button
                                style={{ backgroundColor: "grey" }}
                                onClick={() => {
                                    location.reload()
                                }}
                            >
                                リロード
                            </button>
                            <button
                                style={{ backgroundColor: "lightgray" }}
                                onClick={() => {
                                    location.href =
                                        location.href.split("?")[0] + "?" + new Date().getTime()
                                }}
                            >
                                スーパーリロード
                            </button>
                            <button
                                style={{ backgroundColor: "dimgray" }}
                                onClick={() => {
                                    window.Notification.requestPermission().then((permission) => {
                                        if (permission === "granted") {
                                            getToken(messaging, {
                                                vapidKey: import.meta.env.VITE_FCM_PUBLIC_KEY,
                                            }).then((token) => {
                                                window.alert(token)
                                            })
                                        }
                                    })
                                }}
                            >
                                fcmトークン取得
                            </button>
                        </div>
                    )}
                    <p className={isIphone() ? styles.versionByIPhone : styles.versionByAndroid}>
                        Version 1.16.999
                    </p>
                </div>
            </div>
            {isShowHistoryPopup && (
                <HistoryOfVisitWithToastPopup
                    setIsShowPopup={setIsShowHistoryPopup}
                    isHistoryOfVisit={true}
                />
            )}
            {isShowBadgePopup && (
                <BadgeCollectionViewWithToastPopup
                    setIsShowPopup={setIsShowBadgePopup}
                    isBadge={true}
                />
            )}
            {isShowQuestionPopup && (
                <QuestionViewWithToastPopup
                    setIsShowPopup={setIsShowQuestionPopup}
                    isQuestion={true}
                />
            )}
            {isShowTermsPopup && (
                <TermsWithToastPopup setIsShowPopup={setIsShowTermsPopup} isTerms={true} />
            )}
            {isOpenModal && (
                <CropperModal
                    setIsOpenModal={setIsOpenModal}
                    dataURL={dataURL}
                    userImageInputRef={userImageInputRef}
                />
            )}
            {isShowNewsPopup && (
                <NewsViewWithToastPopup setIsShowPopup={setIsShowNewsPopup} isNews={true} />
            )}
        </>
    )
}
