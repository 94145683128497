import { EventInfo, Jinja, JinjaInfo } from "@/repositories/jinjaRepository"
import { eventBuilder } from "./eventBuilder"
import { jinjaInfoBuilder } from "./jinjaInfoBuilder"

export const jinjaBuilder = (
    jinjaValue: Partial<Jinja>,
    jinjaInfoValue: Partial<JinjaInfo>,
    eventValue: Partial<EventInfo>
): Jinja => {
    return {
        jinjaId: "",
        name: "",
        nameKana: "",
        lastWord: "",
        position: { latitude: 1, longitude: 2 },
        parkingPosition: { latitude: 3, longitude: 4 },
        redSealAlbumPublish: false,
        jinjaInfo: jinjaInfoBuilder(jinjaInfoValue),
        relativeJinjaList: [],
        eventList: [eventBuilder(eventValue)],
        ...jinjaValue,
    }
}
