import { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import { TermsPopup } from "@/components/TermsPopup"
import styles from "./TopPage.module.scss"
import lineEnableNormal from "@/assets/topPage/lineEnableNormal.svg"
import lineDisableNormal from "@/assets/topPage/lineDisableNormal.svg"
import lineDisableNarrow from "@/assets/topPage/lineDisableNarrow.svg"
import lineEnableNarrow from "@/assets/topPage/lineEnableNarrow.svg"
import googleEnableNormal from "@/assets/topPage/googleEnableNormal.svg"
import googleDisableNormal from "@/assets/topPage/googleDisableNormal.svg"
import googleDisableNarrow from "@/assets/topPage/googleDisableNarrow.svg"
import googleEnableNarrow from "@/assets/topPage/googleEnableNarrow.svg"
import appleEnableNormal from "@/assets/topPage/appleEnableNormal.svg"
import appleDisableNormal from "@/assets/topPage/appleDisableNormal.svg"
import appleDisableNarrow from "@/assets/topPage/appleDisableNarrow.svg"
import appleEnableNarrow from "@/assets/topPage/appleEnableNarrow.svg"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { ScreenWidths } from "../types/ScreenWidths"
import { isIphone } from "@/functions/isIphone"
import { CloseIcon } from "@/components/icon/CloseIcon"

export const TopPage = () => {
    const [isShowTermsPopup, setIsShowTermsPopup] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isShowDownloadModal, setIsShowDownloadModal] = useState(false)
    const checkBoxRef = useRef<HTMLInputElement>(null)
    const { screenWidth } = useContext(ApplicationContext)!

    useEffect(() => {
        sessionStorage.setItem("scrollPosition", "0")
        if (checkBoxRef.current) {
            if (Number(localStorage.getItem("isCheckAgreementCheckBox")) > 1726153201000) {
                checkBoxRef.current.checked = true
                setIsDisabled(false)
            }
        }
        localStorage.setItem("isShowBlowing", "false")
    }, [])

    return (
        <div className={styles.topPageArea}>
            <h1>倭姫命の御朱印巡り</h1>
            {isShowTermsPopup && <TermsPopup setIsTermsPopup={setIsShowTermsPopup} />}
            <div className={styles.loginArea}>
                {isIphone() && !window.matchMedia("(display-mode: standalone)").matches && (
                    <>
                        <p className={styles.downloadText}>
                            ※ホーム画面に追加すると快適に利用できます。設定方法は
                            <span
                                onClick={() => {
                                    setIsShowDownloadModal(true)
                                }}
                            >
                                こちら
                            </span>
                        </p>
                    </>
                )}
                <label htmlFor="agreementCheckBox" className={styles.agreementArea}>
                    <input
                        ref={checkBoxRef}
                        id="agreementCheckBox"
                        type="checkbox"
                        value="利用規約に同意する"
                        className={styles.checkbox}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setIsDisabled(!e.target.checked)
                        }
                    />
                    <button onClick={() => setIsShowTermsPopup(true)}>利用規約</button>
                    に同意する
                </label>

                <div className={styles.loginButtonArea}>
                    <LoginButton
                        isDisabled={isDisabled}
                        provider={"line"}
                        screenWidth={screenWidth}
                        setIsDisabled={setIsDisabled}
                    />
                    <LoginButton
                        isDisabled={isDisabled}
                        provider={"google"}
                        screenWidth={screenWidth}
                        setIsDisabled={setIsDisabled}
                    />
                    <LoginButton
                        isDisabled={isDisabled}
                        provider={"apple"}
                        screenWidth={screenWidth}
                        setIsDisabled={setIsDisabled}
                    />
                </div>
            </div>
            {isShowDownloadModal && (
                <div className={styles.downloadModalArea}>
                    <div className={styles.downloadModal}>
                        <button
                            onClick={() => {
                                setIsShowDownloadModal(false)
                            }}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

type LoginProps = {
    isDisabled: boolean
    provider: "google" | "apple" | "line"
    screenWidth: ScreenWidths
    setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const LoginButton = (props: LoginProps) => {
    const images = {
        apple: {
            narrow: {
                disableImg: appleDisableNarrow,
                enableImg: appleEnableNarrow,
            },
            normal: {
                disableImg: appleDisableNormal,
                enableImg: appleEnableNormal,
            },
        },
        google: {
            narrow: {
                disableImg: googleDisableNarrow,
                enableImg: googleEnableNarrow,
            },
            normal: {
                disableImg: googleDisableNormal,
                enableImg: googleEnableNormal,
            },
        },
        line: {
            narrow: {
                disableImg: lineDisableNarrow,
                enableImg: lineEnableNarrow,
            },
            normal: {
                disableImg: lineDisableNormal,
                enableImg: lineEnableNormal,
            },
        },
    }

    const getImgProps = () => {
        const isNarrow = props.screenWidth === ScreenWidths.NARROW
        const src =
            images[props.provider][isNarrow ? "narrow" : "normal"][
                props.isDisabled ? "disableImg" : "enableImg"
            ]
        const alt = `${props.provider}${props.isDisabled ? "Disable" : "Enable"}${isNarrow ? "Narrow" : "Normal"}`
        return { src, alt }
    }

    return (
        <button
            disabled={props.isDisabled}
            onClick={() => {
                props.setIsDisabled(true)
                window.location.href = `${import.meta.env.VITE_LOGIN_REDIRECT_BASE_URL}${props.provider}`
                localStorage.setItem("isCheckAgreementCheckBox", `${Date.now()}`)
            }}
        >
            <img {...getImgProps()} />
        </button>
    )
}
