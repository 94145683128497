import { axiosGet } from "../clients/httpClient"
import { AxiosResponse } from "axios"

export type Position = {
    latitude: number
    longitude: number
}

export type JinjaInfo = {
    detailInfo: string
    address: string
    phoneNumber: string
    receptionInfo: string
    officialHp: string
    restRoom: string
    parking: string
    mapLink: string
    tripGuideLink: string
}

export type Jinja = {
    jinjaId: string
    name: string
    nameKana: string
    lastWord: string
    position: Position
    parkingPosition: Position
    redSealAlbumPublish: boolean
    jinjaInfo: JinjaInfo
    relativeJinjaList: string[]
    eventList: EventInfo[]
}

export type EventInfo = {
    name: string
    details: string
    month: number
    date: number
    time: string
}

export const getJinjaList = async (): Promise<Jinja[]> => {
    const { data } = (await axiosGet("/api/jinja/jinja-list")) as AxiosResponse
    return data
}
