import { BackIcon } from "@/components/icon/BackIcon"
import styles from "./SinglePhotoView.module.scss"
import { useContext, useEffect } from "react"
import { Zoom } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { type Swiper as SwiperClass } from "swiper/types"
import "swiper/css/zoom"
import { ApplicationContext2025 } from "../contexts/ApplicationContextProvider2025"
import { jinjaPictureOriginalList } from "@/services/JinjaPhotoImageList"

type singlePhotoProps = {
    jinjaIdIndex: number
}

export const SinglePhotoView2025 = (props: singlePhotoProps) => {
    const { setSinglePhotoViewMode, selectPictureIndex, setSelectPictureIndex } =
        useContext(ApplicationContext2025)!

    useEffect(() => {
        return () => {
            setSinglePhotoViewMode(undefined)
        }
    }, [])
    const handleZoomChange = (swiper: SwiperClass, scale: number) => {
        if (scale !== 1) {
            swiper.allowSlidePrev = false
            swiper.allowSlideNext = false
        } else {
            swiper.allowSlidePrev = true
            swiper.allowSlideNext = true
        }
    }

    return (
        <div className={styles.singlePhotoArea}>
            <button
                onClick={() => {
                    setSinglePhotoViewMode(undefined)
                }}
                className={styles.backButton}
            >
                <BackIcon />
            </button>
            <Swiper
                zoom={true}
                modules={[Zoom]}
                className={styles.singlePhoto}
                pagination={{ clickable: true, dynamicBullets: true }}
                slidesPerView={1}
                centeredSlides={true}
                initialSlide={selectPictureIndex}
                onSlideChange={(swiper) => {
                    setSelectPictureIndex(swiper.activeIndex)
                }}
                onZoomChange={handleZoomChange}
            >
                {jinjaPictureOriginalList[props.jinjaIdIndex].jinjaPictures.map(
                    (picture, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="swiper-zoom-container">
                                    <div className={styles.mainImgWrapper}>
                                        <img
                                            src={picture}
                                            alt="singlePhoto"
                                            className={styles.mainImg}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    }
                )}
            </Swiper>
        </div>
    )
}
