import { Jinja } from "@/repositories/jinjaRepository"
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { cloneDeep } from "lodash"
import { selectedJinjaOfOverPopup } from "@/functions/swalPopups/selectedJinjaOfOverPopup"
import selectJinjaPinIcon from "@/assets/generalIcons/jinjaSelectPinIcon.svg"
import visitedJinjaPinIcon from "@/assets/generalIcons/visitedJinjaPinIcon.svg"
import disableJinjaPinIcon from "@/assets/generalIcons/disableJinjaPinIcon.svg"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"

interface JinjaMarkerProps extends google.maps.MarkerOptions {
    jinja: Jinja
    selectJinjaList: Jinja[]
    setSelectJinjaList: Dispatch<SetStateAction<Jinja[]>>
}

export const JinjaMarker: React.FC<JinjaMarkerProps> = (props: JinjaMarkerProps) => {
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>(
        new google.maps.marker.AdvancedMarkerElement()
    )
    const { histories } = useContext(ApplicationContext)!

    const clickActionJinjaMarker = () => {
        const foundSelectJinja = props.selectJinjaList.some((jinja) => {
            return jinja.jinjaId === props.jinja.jinjaId
        })
        if (!foundSelectJinja) {
            props.setSelectJinjaList((prevState) => {
                const newState = cloneDeep(prevState)
                newState.push(props.jinja)
                return newState
            })
        }
    }

    const jinjaPinSelector = (jinjaId: string): string => {
        const selectJinjaIdList = props.selectJinjaList.map((selectJinja) => selectJinja.jinjaId)
        if (selectJinjaIdList.includes(jinjaId)) {
            return selectJinjaPinIcon
        } else if (histories.some((historyJinja) => historyJinja.jinjaId === jinjaId)) {
            return visitedJinjaPinIcon
        } else {
            return disableJinjaPinIcon
        }
    }

    //markerのdesignの設定
    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.marker.AdvancedMarkerElement())
        }

        if (marker) {
            marker.onclick = () => {
                props.selectJinjaList.length === 9
                    ? selectedJinjaOfOverPopup()
                    : clickActionJinjaMarker()
            }
            marker.position = props.position
            marker.map = props.map as google.maps.Map
            const pin = document.createElement("img")
            pin.style.width = "28px"
            pin.style.height = "32px"
            pin.src = jinjaPinSelector(props.jinja.jinjaId)
            marker.content = pin
        }

        return () => {
            if (marker) {
                marker.map = null
            }
        }
    }, [marker, props])
    return null
}
