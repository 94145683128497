import ReactDOM from "react-dom/client"
import "./index.css"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { ApplicationContextProvider } from "./contexts/ApplicationContextProvider"
import { AlbumContextProvider } from "./contexts/AlbumContextProvider"
import { ApplicationContextProvider2025 } from "./contexts/ApplicationContextProvider2025"
import { is2025 } from "@/functions/is2025"
import App2025 from "./App2025"
ReactDOM.createRoot(document.getElementById("root")!).render(
    <>
        {!is2025() ? (
            <BrowserRouter>
                <ApplicationContextProvider>
                    <AlbumContextProvider>
                        <App />
                    </AlbumContextProvider>
                </ApplicationContextProvider>
            </BrowserRouter>
        ) : (
            <BrowserRouter>
                <ApplicationContextProvider2025>
                    <App2025 />
                </ApplicationContextProvider2025>
            </BrowserRouter>
        )}
    </>
)
