import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react"
import defaultUserIcon from "@/assets/defaultUserIcon.svg"
import { Jinja } from "@/repositories/jinjaRepository"
import { Tour } from "@/repositories/tourRepository"
import { isIphone } from "@/functions/isIphone"
import { ScreenWidths } from "../types/ScreenWidths"
import { UserPicture } from "./AlbumContextProvider"
import { HistoryOfVisit } from "@/repositories/historyOfVisitRepository"
import { Swiper as SwiperType } from "swiper"

export type SinglePhotoViewModeType = "jinjaDetail" | "album" | undefined

export type ApplicationContextValue = Readonly<{
    histories: HistoryOfVisit[]
    setHistories: Dispatch<SetStateAction<HistoryOfVisit[]>>
    userIcon: string
    setUserIcon: (userIconSrc: string) => void
    isSelectMode: boolean
    setIsSelectMode: (isSelectMode: boolean) => void
    numberOfSelectedPictures: number
    setNumberOfSelectedPictures: (numberOfSelectedPictures: number) => void
    jinjaList: Jinja[]
    setJinjaList: (jinjaList: Jinja[]) => void
    tourList: Tour[]
    setTourList: (tourList: Tour[]) => void
    isShowDeletePopup: boolean
    setIsShowDeletePopup: (isDeletePopup: boolean) => void
    singlePhotoViewMode: SinglePhotoViewModeType
    setSinglePhotoViewMode: Dispatch<SetStateAction<SinglePhotoViewModeType>>
    month: number
    setMonth: (month: number) => void
    selectedDates: number[]
    setSelectedDates: Dispatch<SetStateAction<number[]>>
    areaHeight: number
    setAreaHeight: (areaHeight: number) => void
    screenWidth: ScreenWidths
    toursAreaRef: React.RefObject<HTMLDivElement>
    imageElementsRef: React.MutableRefObject<HTMLImageElement[]>
    calenderSwiperRef: React.MutableRefObject<SwiperType | null>
    jinjaPictures: UserPicture[]
    setJinjaPictures: React.Dispatch<React.SetStateAction<UserPicture[]>>
    newsListLength: number
    readNewsList: boolean[]
    setReadNewsList: React.Dispatch<React.SetStateAction<boolean[]>>
    isShowAnswer1: boolean
    setIsShowAnswer1: React.Dispatch<React.SetStateAction<boolean>>
    isShowAnswer2: boolean
    setIsShowAnswer2: React.Dispatch<React.SetStateAction<boolean>>
    isShowQuestionPopup: boolean
    setIsShowQuestionPopup: React.Dispatch<React.SetStateAction<boolean>>
    favoriteList: string[]
    setFavoriteList: React.Dispatch<React.SetStateAction<string[]>>
    stateOfJinjaFilter: (boolean | undefined)[]
    setStateOfJinjaFilter: React.Dispatch<React.SetStateAction<(boolean | undefined)[]>>
    stateOfJinjaFilterForMap: (boolean | undefined)[]
    setStateOfJinjaFilterForMap: React.Dispatch<React.SetStateAction<(boolean | undefined)[]>>
    range: number
    setRange: React.Dispatch<React.SetStateAction<number>>
    isShowNewsPopup: boolean
    setIsShowNewsPopup: React.Dispatch<React.SetStateAction<boolean>>
    isShowNews8: boolean
    setIsShowNews8: React.Dispatch<React.SetStateAction<boolean>>
}>

export const ApplicationContext = createContext<ApplicationContextValue | null>(null)

type Props = Readonly<{ children: ReactNode }>
export const ApplicationContextProvider: React.FC<Props> = ({ children }) => {
    const [histories, setHistories] = useState<HistoryOfVisit[]>([])
    const [isSelectMode, setIsSelectMode] = useState(false)
    const [userIcon, setUserIcon] = useState<string>(defaultUserIcon)
    const [numberOfSelectedPictures, setNumberOfSelectedPictures] = useState(0)
    const [jinjaList, setJinjaList] = useState<Jinja[]>([])
    const [tourList, setTourList] = useState<Tour[]>([])
    const [isShowDeletePopup, setIsShowDeletePopup] = useState(false)
    const [singlePhotoViewMode, setSinglePhotoViewMode] =
        useState<SinglePhotoViewModeType>(undefined)
    const [aspectRatio, setAspectRatio] = useState(0)
    const [areaHeight, setAreaHeight] = useState(0)
    const toursAreaRef = useRef<HTMLDivElement>(null)
    const imageElementsRef = useRef<HTMLImageElement[]>([])
    const calenderSwiperRef = useRef<SwiperType | null>(null)
    const [jinjaPictures, setJinjaPictures] = useState<UserPicture[]>([])
    const [readNewsList, setReadNewsList] = useState<boolean[]>([])
    const [isShowAnswer1, setIsShowAnswer1] = useState(false)
    const [isShowAnswer2, setIsShowAnswer2] = useState(false)
    const [isShowQuestionPopup, setIsShowQuestionPopup] = useState(false)
    const [favoriteList, setFavoriteList] = useState<string[]>([])
    const [stateOfJinjaFilter, setStateOfJinjaFilter] = useState<(boolean | undefined)[]>([
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
    ])
    const [stateOfJinjaFilterForMap, setStateOfJinjaFilterForMap] = useState<
        (boolean | undefined)[]
    >([undefined, undefined, undefined, undefined, undefined])
    const [range, setRange] = useState<number>(8)
    const [isShowNewsPopup, setIsShowNewsPopup] = useState(false)
    const [isShowNews8, setIsShowNews8] = useState(false)

    useEffect(() => {
        setAspectRatio(screen.width / screen.height)
        const localReadNewsList: string = localStorage.getItem("readNewsList")
            ? localStorage.getItem("readNewsList")!
            : JSON.stringify([])
        setReadNewsList(JSON.parse(localReadNewsList))
    }, [])

    useEffect(() => {
        if (isIphone()) {
            if (aspectRatio > 8.5 / 16 && aspectRatio < 9.5 / 16) {
                setAreaHeight(window.innerHeight - 72)
            } else {
                setAreaHeight(window.innerHeight - 88)
            }
        }
    }, [aspectRatio])

    const now = new Date()
    const currentMonth = now.getMonth()
    const currentDate = now.getDate()
    const [month, setMonth] = useState(currentMonth)
    const initialSelectedDates = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    initialSelectedDates[currentMonth] = currentDate
    const [selectedDates, setSelectedDates] = useState<number[]>(initialSelectedDates)
    const newsListLength = 8
    return (
        <ApplicationContext.Provider
            value={{
                histories,
                setHistories,
                userIcon,
                setUserIcon,
                isSelectMode,
                setIsSelectMode,
                numberOfSelectedPictures,
                setNumberOfSelectedPictures,
                jinjaList,
                setJinjaList,
                tourList,
                setTourList: setTourList,
                isShowDeletePopup,
                setIsShowDeletePopup,
                singlePhotoViewMode,
                setSinglePhotoViewMode,
                month,
                setMonth,
                selectedDates,
                setSelectedDates,
                areaHeight,
                setAreaHeight,
                screenWidth: screen.width >= 360 ? ScreenWidths.NORMAL : ScreenWidths.NARROW,
                toursAreaRef,
                imageElementsRef,
                calenderSwiperRef,
                jinjaPictures,
                setJinjaPictures,
                newsListLength,
                readNewsList,
                setReadNewsList,
                isShowAnswer1,
                setIsShowAnswer1,
                isShowAnswer2,
                setIsShowAnswer2,
                isShowQuestionPopup,
                setIsShowQuestionPopup,
                favoriteList,
                setFavoriteList,
                stateOfJinjaFilter,
                setStateOfJinjaFilter,
                stateOfJinjaFilterForMap,
                setStateOfJinjaFilterForMap,
                range,
                setRange,
                isShowNewsPopup,
                setIsShowNewsPopup,
                isShowNews8,
                setIsShowNews8,
            }}
        >
            {children}
        </ApplicationContext.Provider>
    )
}
