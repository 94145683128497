import Swal from "sweetalert2"
import errorIcon from "@/assets/errorIcon.svg"
import styles from "./swalFires.module.scss"
import { NavigateFunction } from "react-router-dom"
import React from "react"

export const terminationOfServicePopup = (
    navigate: NavigateFunction,
    setIsShowNewsPopup: React.Dispatch<React.SetStateAction<boolean>>,
    setIsShowNews8: React.Dispatch<React.SetStateAction<boolean>>
) => {
    Swal.fire({
        html:
            `<img alt="errorIcon" src=${errorIcon} />` +
            `<h3>サービス終了のお知らせ</h3>` +
            `<p>12月31日をもちまして<br/>サービスの提供を終了いたします<br/>掲載神社を引き続きご覧頂くには<br/>12月の更新を”必ず”行ってください</p>` +
            `<p>詳細は<span id="terminationOfNotification">こちら</span></p>` +
            `<p>今後のアプリ開発に役立てるため<br/>皆さまからのご意見をお伺いしたく<br/>アンケートのご協力をお願いします</p>` +
            `<p>アンケートは<span id="survey">こちら</span></p>` +
            `<button class=${styles.closeButton} id="terminationOfServicePopup_closeButton">閉じる</button>`,
        showConfirmButton: false,
        allowOutsideClick: false,
        customClass: {
            popup: styles.terminationOfServicePopup,
            htmlContainer: styles.htmlContainer,
        },
        didOpen: () => {
            const closeButton = document.getElementById("terminationOfServicePopup_closeButton")
            closeButton!.onclick = () => {
                Swal.close()
            }
            const terminationOfNotificationEle = document.getElementById(
                "terminationOfNotification"
            )
            terminationOfNotificationEle!.onclick = () => {
                navigate("/app/mypage")
                setIsShowNewsPopup(true)
                setIsShowNews8(true)
                Swal.close()
            }
            const survey = document.getElementById("survey")
            survey!.onclick = () => {
                window.open(
                    "https://forms.office.com/Pages/ResponsePage.aspx?id=XjPB0YL1qUK2_l4aFuubyCXvl1r_fCxKnoF-DyJDmZBUQ1FRU1VUNEc1WVRDSDRXTDJTSE1IU09WOC4u",
                    "_blank"
                )
                Swal.close()
            }
        },
    })
}
