// サービス終了のためテストは省略

import { BackIcon } from "@/components/icon/BackIcon"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import showAllButton from "@/assets/jinjaDetailPage/showAllButton.svg"
import parkingIcon from "@/assets/jinjaDetailPage/parkingIcon.svg"
import currentLocationIcon from "@/assets/jinjaDetailPage/currentLocationIcon.svg"
import toiletIcon from "@/assets/jinjaDetailPage/toiletIcon.svg"
import homePageIcon from "@/assets/jinjaDetailPage/homePageIcon.svg"
import phoneNumberIcon from "@/assets/jinjaDetailPage/phoneNumberIcon.svg"
import redSealIcon from "@/assets/jinjaDetailPage/redSealIcon.svg"
import toriiIcon from "@/assets/jinjaDetailPage/toriiIcon.svg"
import copyIcon from "@/assets/jinjaDetailPage/copyIcon.svg"
import copyCheckIcon from "@/assets/jinjaDetailPage/copyCheckIcon.svg"
import tripGudieIcon from "@/assets/jinjaDetailPage/tripGuideIcon.svg"
import favoriteOffIcon from "@/assets/homePage/favoriteOffIcon.svg"
import favoriteOnIcon from "@/assets/homePage/favoriteOnIcon.svg"
import checkIcon from "@/assets/checkInIcon.svg"

import styles from "./JinjaDetailPage.module.scss"
import React, { useContext, useEffect, useRef, useState } from "react"
import { jinjaBuilder } from "../tests/testBuilder/jinjaBuilder"
import { Jinja } from "@/repositories/jinjaRepository"
import { jinjaImageList, jinjaPictureList } from "@/services/JinjaPhotoImageList"
import { ApplicationContext2025 } from "../contexts/ApplicationContextProvider2025"
import { tourList2025 } from "../data/tourList2025"
import { jinjaList2025 } from "../data/jinjaList2025"
import { cloneDeep } from "lodash"
import { SinglePhotoView2025 } from "@/components/SinglePhotoView2025"

export const JinjaDetailPage2025 = () => {
    const {
        singlePhotoViewMode,
        setSinglePhotoViewMode,
        favoriteList,
        setFavoriteList,
        setSelectPictureIndex,
    } = useContext(ApplicationContext2025)!
    const jinjaList = jinjaList2025
    const detailPageAreaRef = useRef<HTMLDivElement>(null)
    // const { selectPictureIndex, setSelectPictureIndex } = useContext(AlbumContext)!
    const jinjaIdOrUndefined = useParams().jinjaId
    const jinjaId = jinjaIdOrUndefined ? jinjaIdOrUndefined : ""
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as { orderNumber: number; tourName: string; tourNameKana: string }
    const [isShowAll, setIsShowAll] = useState(false)
    // const [isClickable, setIsClickable] = useState(true)
    const selectJinjaOrUndefined: Jinja | undefined = jinjaList.find((jinja) => {
        return jinja.jinjaId == jinjaId
    })
    const selectJinja: Jinja = selectJinjaOrUndefined
        ? selectJinjaOrUndefined!
        : jinjaBuilder({}, {}, {})

    useEffect(() => {
        detailPageAreaRef.current!.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }, [location.pathname])
    useEffect(() => {
        const jinjaNumber = parseInt(jinjaId.slice(-2), 10)
        if (detailPageAreaRef.current) {
            detailPageAreaRef.current.style.background = `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url(${jinjaImageList[jinjaNumber]}), lightgray 50%`
            detailPageAreaRef.current.style.backgroundPosition = "center"
            detailPageAreaRef.current.style.backgroundSize = "cover"
            detailPageAreaRef.current.style.backgroundAttachment = "fixed"
        }
    }, [])

    const createRelativeJinjaText = () => {
        const filteredRelativeJinjaList = selectJinja.relativeJinjaList.filter((jinjaName) => {
            return !jinjaName.includes(selectJinja.name || "")
        })
        if (filteredRelativeJinjaList.length === 0) {
            return <p className={styles.nothing}>無し</p>
        } else {
            const relativeJinjaJsxList: JSX.Element[] = []
            filteredRelativeJinjaList.forEach((jinjaName, index) => {
                const findJinja = jinjaList.find((jinja) => {
                    return `${jinja.name}${jinja.lastWord}` === jinjaName
                })!
                const findTour = tourList2025.find((tour) => {
                    return tour.jinjaList.some((jinja) => {
                        return jinja.jinjaId === findJinja.jinjaId
                    })
                })!
                relativeJinjaJsxList.push(
                    <p
                        onClick={() => {
                            navigate(`/app/jinja-detail/${findJinja.jinjaId}`, {
                                state: {
                                    orderNumber: findTour.orderNumber,
                                    tourName: findTour.tourName,
                                    tourNameKana: findTour.tourNameKana,
                                },
                            })
                        }}
                        key={`relativeJinjaName-${index}`}
                    >
                        {jinjaName}
                    </p>
                )
                if (filteredRelativeJinjaList.length - 1 > index)
                    relativeJinjaJsxList.push(<span key={`slash-${index}`}>/</span>)
            })
            return relativeJinjaJsxList
        }
    }

    const convertParkingElement = (parking: string) => {
        if (parking.includes("無し")) {
            return (
                <div>
                    <p className={styles.parkingTitle}>無料駐車場</p>
                    <p>{parking}</p>
                </div>
            )
        } else if (parking.includes("https")) {
            const index = parking.indexOf("h")
            const parkingText = parking.substring(0, index)
            const parkingUrl = parking.substring(index, parking.length)
            return (
                <div>
                    <a href={parkingUrl} target={"_blank"}>
                        無料駐車場
                    </a>
                    <p>{parkingText}</p>
                </div>
            )
        }
    }

    const getJinjaVisitDate = (jinjaName: string) => {
        const jinjaVisitDate = localStorage.getItem(jinjaName)
        if (jinjaVisitDate) {
            return jinjaVisitDate.replace("年", "/").replace("月", "/").replace("日", "")
        }
        return null
    }

    const convertPhoneNumberText = (phoneNumber: string) => {
        const indexNumber = phoneNumber.includes("（")
            ? phoneNumber.indexOf("（")
            : phoneNumber.length
        const telNumber = phoneNumber.slice(0, indexNumber)
        if (phoneNumber[0] === "0") {
            return (
                <div>
                    <p>連絡先</p>
                    <div>
                        <a href={`tel:${telNumber}`} style={{ textDecoration: "none" }}>
                            {telNumber}
                        </a>
                        <p>{phoneNumber.slice(indexNumber)}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <p>連絡先</p>
                    <div>
                        <p>無し</p>
                    </div>
                </div>
            )
        }
    }
    return (
        <div>
            <div
                id="detailPageArea"
                className={`${styles.detailPageArea}`}
                style={{ height: "100dvh" }}
                ref={detailPageAreaRef}
            >
                <button
                    className={styles.backButton}
                    onClick={() => {
                        navigate("/app/home")
                    }}
                >
                    <BackIcon />
                </button>
                {getJinjaVisitDate(selectJinja.name) && (
                    <div className={styles.visitDateArea}>
                        <img src={checkIcon} alt="checkIcon" />
                        <p>
                            参拝日
                            <br />
                            {getJinjaVisitDate(selectJinja.name)}
                        </p>
                    </div>
                )}
                <h3>
                    {state.orderNumber}.<ruby data-ruby={state.tourNameKana}>{state.tourName}</ruby>
                </h3>
                <div className={styles.jinjaNameArea}>
                    <h2>
                        <ruby data-ruby={selectJinja.nameKana}>{selectJinja.name}</ruby>
                        {selectJinja.lastWord}
                    </h2>
                    {favoriteList.includes(jinjaId!) ? (
                        <img
                            alt={`favoriteOnIcon`}
                            src={favoriteOnIcon}
                            onClick={() => {
                                const removedFavoriteList = favoriteList.filter(
                                    (favoriteJinjaId) => favoriteJinjaId !== jinjaId
                                )
                                localStorage.setItem(
                                    "favoriteList",
                                    JSON.stringify(removedFavoriteList)
                                )
                                setFavoriteList(removedFavoriteList)
                            }}
                        />
                    ) : (
                        <img
                            alt={`favoriteOffIcon`}
                            src={favoriteOffIcon}
                            onClick={() => {
                                const newFavoriteList = cloneDeep(favoriteList)
                                newFavoriteList.push(jinjaId)
                                localStorage.setItem(
                                    "favoriteList",
                                    JSON.stringify(newFavoriteList)
                                )
                                setFavoriteList(newFavoriteList)
                            }}
                        />
                    )}
                </div>
                <div className={styles.articleArea}>
                    <p className={isShowAll ? styles.showAllArticle : styles.article}>
                        {selectJinja.jinjaInfo.detailInfo}
                    </p>
                    {!isShowAll && (
                        <div className={styles.showAllButtonArea}>
                            <button
                                onClick={() => {
                                    setIsShowAll(true)
                                }}
                            >
                                <img alt={"showAllButton"} src={showAllButton} />
                                <p>続きを読む</p>
                            </button>
                        </div>
                    )}
                </div>

                <div className={styles.jinjaPicturesArea}>
                    {[...jinjaPictureList[Number(jinjaId.slice(-2))].jinjaPictures]
                        .slice(0, 3)
                        .map((picture, index) => {
                            return (
                                <img
                                    src={picture}
                                    alt={`jinjaPicture${index}`}
                                    key={index}
                                    onClick={() => {
                                        setSinglePhotoViewMode("jinjaDetail")
                                        setSelectPictureIndex(index)
                                    }}
                                />
                            )
                        })}
                </div>
                <div className={styles.informationArea}>
                    <div className={styles.addressContents}>
                        <img
                            className={styles.pinIcon}
                            alt="currentLocationIcon"
                            src={currentLocationIcon}
                        />
                        <a href={selectJinja.jinjaInfo.mapLink} target="_blank">
                            {selectJinja.jinjaInfo.address}
                        </a>
                        <img
                            alt={"copyIcon"}
                            src={copyIcon}
                            className={styles.copyIcon}
                            onClick={async (e) => {
                                const target = e.target as HTMLImageElement
                                target.src = copyCheckIcon
                                target.alt = "copyCheckIcon"
                                await navigator.clipboard.writeText(selectJinja.jinjaInfo.address)
                                setTimeout(() => {
                                    target.src = copyIcon
                                    target.alt = "copyIcon"
                                }, 2000)
                            }}
                        />
                    </div>
                    <div className={styles.phoneNumberArea}>
                        <img alt="phoneNumberIcon" src={phoneNumberIcon} />
                        {convertPhoneNumberText(selectJinja.jinjaInfo.phoneNumber)}
                    </div>
                    <div className={styles.icOutlineWatchLaterArea}>
                        <img alt="redSealIcon" src={redSealIcon} />
                        <div>
                            <p>御朱印</p>
                            <p>{selectJinja.jinjaInfo.receptionInfo}</p>
                        </div>
                    </div>
                    <div className={styles.officialHpArea}>
                        <img alt="homePageIcon" src={homePageIcon} />
                        <div>
                            {selectJinja.jinjaInfo.officialHp === "無し" ? (
                                <>
                                    <p>公式HP</p>
                                    <p>無し</p>
                                </>
                            ) : (
                                <a href={selectJinja.jinjaInfo.officialHp} target="_blank">
                                    公式HP
                                </a>
                            )}
                        </div>
                    </div>
                    <div className={styles.toiletArea}>
                        <img alt="toiletIcon" src={toiletIcon} />
                        <div>
                            <p>お手洗い</p>
                            <p>{selectJinja.jinjaInfo.restRoom}</p>
                        </div>
                    </div>
                    <div className={styles.parkingArea}>
                        <img alt="parkingIcon" src={parkingIcon} />
                        {convertParkingElement(selectJinja.jinjaInfo.parking)}
                    </div>
                    <div className={styles.tripGuideArea}>
                        <img alt="tripGuideIcon" src={tripGudieIcon} />
                        <a href={selectJinja.jinjaInfo.tripGuideLink} target="_blank">
                            周辺観光ガイド
                        </a>
                    </div>
                    <div className={styles.relativeJinjaArea}>
                        <img alt="toriiIcon" src={toriiIcon} />
                        <div>
                            <p>関連神社</p>
                            <div>{createRelativeJinjaText()}</div>
                        </div>
                    </div>
                </div>
            </div>
            {singlePhotoViewMode === "jinjaDetail" && (
                // selectPictureIndex !== undefined &&
                <SinglePhotoView2025 jinjaIdIndex={Number(jinjaId.slice(-2))} />
            )}
        </div>
    )
}
