import { Jinja } from "@/repositories/jinjaRepository"

export const jinjaList2025: Jinja[] = [
    {
        jinjaId: "00000000-0000-0000-0000-000000000002",
        name: "阿紀",
        nameKana: "あき",
        lastWord: "神社",
        position: {
            latitude: 34.4783458,
            longitude: 135.9238834,
        },
        parkingPosition: {
            latitude: 34.4783296,
            longitude: 135.9246292,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "倭姫命が御杖代（みつえしろ）となってから最初に天照大御神を奉斎したお宮とされ、周辺には祭祀場（さいしじょう）などの遺跡が残ります。格式高い神明（しんめい）造の本殿は西向きに建ち、その場から伊勢神宮を遥拝（ようはい）することができます。",
            phoneNumber: "0744-23-7695",
            address: "奈良県宇陀市大宇陀迫間252",
            receptionInfo: "書き置き（無ければ社務所裏手 元宮司様宅） 300円",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/goqyHded7iLWuMeo6",
            mapLink: "https://maps.app.goo.gl/Lb5QvzmA3zyGKo576",
            tripGuideLink: "https://www.uda-kankou.jp/",
        },
        relativeJinjaList: ["阿紀神社", "劔主神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000003",
        name: "篠畑",
        nameKana: "ささはた",
        lastWord: "神社",
        position: {
            latitude: 34.5553167,
            longitude: 135.9881326,
        },
        parkingPosition: {
            latitude: 34.5472941,
            longitude: 135.9829392,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "山裾に立つ乳白色の鳥居をくぐり石段を上ると、厳かな雰囲気の境内に神明（しんめい）造の本殿があります。倭姫命はこの地で大采禰奈（おおうねな）という童女に出会い、伊勢までの旅を共にすることになります。",
            phoneNumber: "無し",
            address: "奈良県宇陀市榛原山辺三2235",
            receptionInfo: "書き置き 300円",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/Cxex8Uj7e7G7HYscA",
            mapLink: "https://maps.app.goo.gl/KdZs9DNHFHeGRCfJ9",
            tripGuideLink: "https://www.uda-kankou.jp/",
        },
        relativeJinjaList: ["篠畑神社", "葛神社", "御杖神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000025",
        name: "大村",
        nameKana: "おおむら",
        lastWord: "神社",
        position: {
            latitude: 34.6704532,
            longitude: 136.1836888,
        },
        parkingPosition: {
            latitude: 34.6700976,
            longitude: 136.1838186,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "倭姫命の弟で日本武尊の叔父である「大村の神（息速別命 いこはやわけのみこと）が祀（まつ）られています。また、大村神社は「地震の神さま」として全国からの参拝者が多く、「欄干なまず」が有名。拝殿の西には「要石」が奉斎され、安政の伊賀上野大地震の際に阿保地区だけは不思議と難を免れたと言われています。",
            phoneNumber: "0595-52-1050",
            address: "三重県伊賀市阿保1555",
            receptionInfo: "社務所（在社時のみ）",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/2YoXUR9AoR487eA96",
            mapLink: "https://maps.app.goo.gl/QDTKJxPdv7mJ8ooF6",
            tripGuideLink: "https://www.iga-guide.com/",
        },
        relativeJinjaList: ["大村神社", "神戸神社"],
        eventList: [
            {
                name: "地震除災大祭",
                details:
                    "嘉永7年6月15日（1854年7月9日）に発生した大地震の際に、伊賀上野付近は甚大な被害を被りましたが、この要石の御霊験によって阿保地区だけは不思議と難を免れたと言われています。大村神社には「ゆらぐとも、よもやぬけまじ要石、大村神のあらんかぎりは」と立札が立てられています。毎年9月1日に地震除災大祭が行われます。",
                month: 9,
                date: 1,
                time: "",
            },
            {
                name: "宵宮祭",
                details:
                    "2日の宵宮には境内・参道の200程の常夜灯にローソク点灯され、伝統神事の獅子舞神楽が奉納されます。境内は笛や太鼓のおはやしが鳴りひびき、秋の夜は祭一色で賑わいます。 ",
                month: 11,
                date: 2,
                time: "08:00-",
            },
            {
                name: "例大祭",
                details:
                    "3日の本祭りは御神前の祭礼につづき境内で沸き立つ湯釜に笹を入れ参拝者に振り掛ける湯神楽神事、大なまず山車の祓式が行われ、その後に奉祝の餅撒きが行われます。",
                month: 11,
                date: 3,
                time: "11:00-",
            },
        ],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000027",
        name: "若宮",
        nameKana: "わかみや",
        lastWord: "神社",
        position: {
            latitude: 34.9766459,
            longitude: 136.3441004,
        },
        parkingPosition: {
            latitude: 34.9756389,
            longitude: 136.3437222,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "甲賀の元伊勢伝承地のひとつ。甲賀武士53家のなかの大河原氏の祖「足利又太郎」を祀り、商売繁盛の神様として知られています。主祭神は月読命（つきよみのみこと）です。",
            phoneNumber: "0748-69-0025",
            address: "滋賀県甲賀市土山町大河原1092",
            receptionInfo: "社務所（在社時のみ）",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/wkw27zbfFEz2P8cD7",
            mapLink: "https://maps.app.goo.gl/Ko6tBEvnTwRN58MEA",
            tripGuideLink: "https://koka-kanko.org/",
        },
        relativeJinjaList: ["若宮神社", "日雲神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000005",
        name: "神戸",
        nameKana: "かんべ",
        lastWord: "神社",
        position: {
            latitude: 34.691156,
            longitude: 136.150932,
        },
        parkingPosition: {
            latitude: 34.6911111,
            longitude: 136.1505278,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "この地では倭姫命が天照大御神に鮎をお供えした伝承があり、古例にならい、現在でも現在も神宮の祭典に奉納しています。神明（しんめい）造の本殿は、式年遷宮（しきねんせんぐう）の古材を用いて二十年に一度造営されています。",
            phoneNumber: "0595-38-1063",
            address: "三重県伊賀市上神戸317",
            receptionInfo: "社務所（不在の場合はご連絡を） 300円",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/8NsEekMVGXyUABJC9",
            mapLink: "https://maps.app.goo.gl/ydY4ou9izP6DLTkR9",
            tripGuideLink: "https://www.iga-guide.com/",
        },
        relativeJinjaList: ["大村神社", "神戸神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000006",
        name: "都美恵",
        nameKana: "つみえ",
        lastWord: "神社",
        position: {
            latitude: 34.843023,
            longitude: 136.246896,
        },
        parkingPosition: {
            latitude: 34.8423333,
            longitude: 136.2469722,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "倭姫命は伊賀国での旅路の最後、柘植（つげ）川の流れるこの地に二年、大御神をお祀（まつ）りしたとされています。丘の上に鎮座する流造の本殿の辺りからは、集落を一望することができます。",
            phoneNumber: "0595-45-3636",
            address: "三重県伊賀市柘植町2280",
            receptionInfo: "書き置き（無い場合はご連絡を） 300円",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/2FQ5QnAc8FmkFL18A",
            mapLink: "https://maps.app.goo.gl/7ShwVnt7dcrVDo7d8",
            tripGuideLink: "https://www.iga-guide.com/",
        },
        relativeJinjaList: ["都美恵神社", "敢国神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000004",
        name: "宇流冨志禰",
        nameKana: "うるふしね",
        lastWord: "神社",
        position: {
            latitude: 34.619028,
            longitude: 136.093226,
        },
        parkingPosition: {
            latitude: 34.6192591,
            longitude: 136.0927403,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "宇陀川と名張川との合流地点近くにあり、水と穀物の神さまを祀（まつ）る神社です。近くの蛭子神社と併せて比定地とされ、名張の地域と密着した、どこか懐かしい雰囲気に満ちています。",
            phoneNumber: "0595-63-0486",
            address: "三重県名張市平尾3319",
            receptionInfo: "社務所（不在の場合はご連絡を） 300円",
            officialHp: "http://urufushine.jp/",
            restRoom: "無し",
            parking: "https://maps.app.goo.gl/Niya2Uqh8bEZrqT8A",
            mapLink: "https://maps.app.goo.gl/E2495Ns1uA7RNusU6",
            tripGuideLink: "https://www.kankou-nabari.jp/",
        },
        relativeJinjaList: ["宇流冨志禰神社", "名居神社", "蛭子神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000008",
        name: "坂田神明宮",
        nameKana: "さかたしんめいぐう",
        lastWord: "",
        position: {
            latitude: 35.3371877,
            longitude: 136.2870804,
        },
        parkingPosition: {
            latitude: 35.3371066,
            longitude: 136.2877074,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "倭姫命一行は船で琵琶湖を北上し、湖畔にあるお宮で2年間、大御神をお祀（まつ）りしたとされます。境内に電車が通る珍しい神社で、線路を越えた奥には荘厳な神明（しんめい）造のご神域があります。",
            phoneNumber: "0749-52-1894",
            address: "滋賀県米原市宇賀野835-2",
            receptionInfo: "書き置きまたは駐車場隣りの宮司様宅 300円",
            officialHp: "無し",
            restRoom: "無し",
            parking: "https://maps.app.goo.gl/gpj9LPRTLy6Xyb9i7",
            mapLink: "https://maps.app.goo.gl/kU9Yhg568hRM6Gvf9",
            tripGuideLink: "https://kitabiwako.jp/",
        },
        relativeJinjaList: ["坂田神明宮"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000038",
        name: "竹大興杵",
        nameKana: "たけおおよど",
        lastWord: "神社",
        position: {
            latitude: 34.560906,
            longitude: 136.6549651,
        },
        parkingPosition: {
            latitude: 34.5610064,
            longitude: 136.6555024,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "倭姫命が飯野高宮より行幸の際に佐々牟江（ささむえ）に船を留め、この地に佐々牟江の宮を造営して、佐々牟江社を定められたのが創始とされています。大淀の浦は、波風が無く穏やかで、姫は大いに悦ばれたそうです。この大淀の浜で、倭姫命は天照大御神の「伊勢に居らんと欲う」とご神勅を受けられました。",
            phoneNumber: "無し",
            address: "三重県多気郡明和町大淀乙1",
            receptionInfo: "御朱印有り（在社時のみ）",
            officialHp: "無し",
            restRoom: "無し",
            parking: "（私有地ですが参拝者は駐車OK）https://maps.app.goo.gl/sUQp4XodpWUCqF6E8",
            mapLink: "https://maps.app.goo.gl/dC7uh55x9Kx2ekhE7",
            tripGuideLink: "https://meiwa.sub.jp/",
        },
        relativeJinjaList: ["竹大興杵神社", "竹佐々夫江神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000009",
        name: "天神",
        nameKana: "てんじん",
        lastWord: "神社",
        position: {
            latitude: 35.4224119,
            longitude: 136.6437341,
        },
        parkingPosition: {
            latitude: 35.422723,
            longitude: 136.643404,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "揖斐川近くの住宅街にご鎮座する社殿の奥には、竹林に囲まれた古代祭祀（さいし）跡があります。神が宿る石「御船代（みふなしろ）石」は、倭姫命が天照大御神のご神体を安置された霊石と伝えられています。",
            phoneNumber: "0585-55-2500",
            address: "岐阜県瑞穂市居倉字中屋敷781",
            receptionInfo: "基本不在のため事前に連絡を 300円",
            officialHp: "無し",
            restRoom: "無し",
            parking: "（居倉公民館を利用ください）https://maps.app.goo.gl/w22sEJ2tnHRcL2wM7",
            mapLink: "https://maps.app.goo.gl/woJTEyKWJQq1yGfx5",
            tripGuideLink: "https://www.kankou-gifu.jp/spot/index_1_2_40.html",
        },
        relativeJinjaList: ["天神神社", "宇波刀神社", "名木林神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000010",
        name: "酒見",
        nameKana: "さかみ",
        lastWord: "神社",
        position: {
            latitude: 35.315861,
            longitude: 136.795288,
        },
        parkingPosition: {
            latitude: 35.3161667,
            longitude: 136.7946111,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "その名が示すように清酒醸造の発祥とされる神社で、境内の「栄水（えいすい）の井」は霊水とされています。倭姫命一行はこの地で乗り船を献上され、伊勢までは川と海を行くことになります。",
            phoneNumber: "0586-73-1365",
            address: "愛知県一宮市今伊勢町本神戸宮山1476",
            receptionInfo: "社務所（駐在時のみ、午前中が多い） 300円",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/KeGA9iPRk6Gk7QhXA",
            mapLink: "https://maps.app.goo.gl/AKi18iLAGWidQUqF7",
            tripGuideLink: "https://www.138ss.com/",
        },
        relativeJinjaList: ["酒見神社", "真清田神社", "御園神明社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000012",
        name: "布氣皇舘太",
        nameKana: "ふけこうたつだい",
        lastWord: "神社",
        position: {
            latitude: 34.8573324,
            longitude: 136.4282865,
        },
        parkingPosition: {
            latitude: 34.8573889,
            longitude: 136.4285,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "たくさんの石灯篭が並ぶ木々に囲まれた参道を行くと、広い境内に拝殿が見えてきます。同じ比定地の忍山（おしやま）神社は徒歩圏内で、倭姫命の甥「日本武尊（やまとたけるのみこと）」の妃･弟橘媛命（おとたちばなひめ）に因（ちな）んだ伝説が残ります。",
            phoneNumber: "0595-82-2502",
            address: "三重県亀山市布気町1663",
            receptionInfo: "要事前連絡（社務所または郵送） 300円（＋郵送代）",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/n9Nit5FSmbFbeztdA",
            mapLink: "https://maps.app.goo.gl/K1M8JJ9xTRpgDGmv7",
            tripGuideLink: "http://kameyama-kanko.com/",
        },
        relativeJinjaList: ["布氣皇舘太神社", "忍山神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000013",
        name: "加良比乃",
        nameKana: "からひの",
        lastWord: "神社",
        position: {
            latitude: 34.6837811,
            longitude: 136.5076435,
        },
        parkingPosition: {
            latitude: 34.6830455,
            longitude: 136.508861,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "現在も藤方の地名が残る丘にひっそりと佇む神社です。倭姫命はこの地で「阿佐加（あざか）の峰の荒ぶる神」を祀り鎮めたとされ、二ヶ所の阿射加神社（大小阿坂町）が比定地とされています。",
            phoneNumber: "059-228-4806（結城神社）",
            address: "三重県津市藤方335",
            receptionInfo: "近隣の結城神社（車で10分ほど、受付時間9:00〜16:00）社務所",
            officialHp: "無し",
            restRoom: "有り",
            parking: "（藤方会館を利用ください）https://maps.app.goo.gl/cusTuQkqih7ZzgHG7",
            mapLink: "https://maps.app.goo.gl/WHEvnMhjdbj7nkFL9",
            tripGuideLink: "https://tsukanko.jp/",
        },
        relativeJinjaList: ["加良比乃神社", "阿射坂神社-小阿坂", "阿射坂神社-大阿坂"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000014",
        name: "神山",
        nameKana: "こうやま",
        lastWord: "神社",
        position: {
            latitude: 34.5252345,
            longitude: 136.5696413,
        },
        parkingPosition: {
            latitude: 34.5251389,
            longitude: 136.5710556,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "倭姫命が櫛を落とされたことに由来する、櫛田川沿いの山麓にある神社です。急な石段を登りきったところに拝殿があり、その奥に木々に囲まれた本殿がひっそりと佇んでいます。",
            phoneNumber: "0598-21-4483（御厨神社）",
            address: "三重県松阪市山添町4",
            receptionInfo: "書き置き（無い場合は御厨神社へご連絡を）",
            officialHp: "無し",
            restRoom: "無し",
            parking: "https://maps.app.goo.gl/4rPVKNFKJ153dtng9",
            mapLink: "https://maps.app.goo.gl/Q9Z1snSe7Joyg2Xx7",
            tripGuideLink: "https://www.matsusaka-kanko.com/",
        },
        relativeJinjaList: ["神山神社", "神戸神舘神明社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000007",
        name: "日雲",
        nameKana: "ひくも",
        lastWord: "神社",
        position: {
            latitude: 34.907969,
            longitude: 136.083121,
        },
        parkingPosition: {
            latitude: 34.908107,
            longitude: 136.082162,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "伊賀から甲賀へ入ると、川は琵琶湖へと注ぐようになります。「みつえしろの道」と名付けられた神秘的な“神林浴道”のある日雲神社は、垂水頓宮（たるみとんぐう）跡や若宮神社に並ぶ比定地のひとつです。",
            phoneNumber: "0748-83-0432",
            address: "滋賀県甲賀市信楽町牧78-78",
            receptionInfo: "無人のため書き置き対応 300円",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/86G9arefH743C4a8A",
            mapLink: "https://maps.app.goo.gl/HTxUtKieHZgcZ7jJ8",
            tripGuideLink: "https://koka-kanko.org/",
        },
        relativeJinjaList: ["若宮神社", "日雲神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000011",
        name: "神館",
        nameKana: "こうたて",
        lastWord: "神社",
        position: {
            latitude: 35.05613,
            longitude: 136.682827,
        },
        parkingPosition: {
            latitude: 35.0558889,
            longitude: 136.6828611,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "御一行の休泊所として建てられた館が「神館」の由来とされる由緒ある神社です。境内にある鏡ヶ池は、倭姫命がここで水を所望された際に、水が湧き出た場所と言い伝えられています。",
            phoneNumber: "0594-22-7863",
            address: "三重県桑名市大字江場1441",
            receptionInfo: "授与所 300円\n公式HP確認ください",
            officialHp: "https://koudate.com/",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/PZWuySX2V4KYhRTSA",
            mapLink: "https://maps.app.goo.gl/FvsYQEqUEzUrV4U68",
            tripGuideLink: "http://kameyama-kanko.com/",
        },
        relativeJinjaList: ["神館神社", "尾野神社", "野志里神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000015",
        name: "竹佐々夫江",
        nameKana: "たけささふえ",
        lastWord: "神社",
        position: {
            latitude: 34.5650666,
            longitude: 136.6496653,
        },
        parkingPosition: {
            latitude: 34.5650556,
            longitude: 136.6500278,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "伊勢湾を南下した御一行が、船を停泊した場所に建てられたとされる神社です。倭姫命は近くの大淀の浜にて「神風の吹く伊勢の国に居たい」と大御神のお告げを受けたとされます。",
            phoneNumber: "090-8739-0406",
            address: "三重県多気郡明和町山大淀3004",
            receptionInfo: "基本土日祝のみ、近隣の竹大興杵神社（徒歩10分ほど）社務所 300円",
            officialHp: "無し",
            restRoom: "無し",
            parking: "https://maps.app.goo.gl/nQJVjKrNyvRJvRp69",
            mapLink: "https://maps.app.goo.gl/YG7juxJz98gbtYBb9",
            tripGuideLink: "https://meiwa.sub.jp/",
        },
        relativeJinjaList: ["竹大興杵神社", "竹佐々夫江神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000016",
        name: "磯",
        nameKana: "いそ",
        lastWord: "神社",
        position: {
            latitude: 34.5196791,
            longitude: 136.7011531,
        },
        parkingPosition: {
            latitude: 34.5189167,
            longitude: 136.7009722,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "河口の近くにあるこの神社は、春にはツツジが咲き誇る名所として有名です。外城田（ときた）川沿いに建てられた鳥居は、倭姫命一行が川を遡上し上陸した跡地と言い伝えられています。",
            phoneNumber: "0596-37-5261",
            address: "三重県伊勢市磯町1069",
            receptionInfo: "神社入口、道路向かい側の社務所（在社時のみ）もしくは、書き置き 300円",
            officialHp: "https://isojinja.com/",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/1tggxR4r2AqDW3Lc9",
            mapLink: "https://maps.app.goo.gl/sb3ExXkNMDbN6LqV6",
            tripGuideLink: "https://www.iseshima-kanko.jp/",
        },
        relativeJinjaList: ["磯神社", "相鹿上神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000017",
        name: "瀧原宮",
        nameKana: "たきはらのみや",
        lastWord: "",
        position: {
            latitude: 34.3661763,
            longitude: 136.4234594,
        },
        parkingPosition: {
            latitude: 34.3676497,
            longitude: 136.4208232,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "倭姫命はさらに佳き宮処を求め山間の奥地へ赴きます。古くから天照大御神の遙宮（とおのみや）といわれ、清流が流れる御手洗場や大木の神宮杉が立ち並ぶ参道がある、美しいお宮です。（内宮の別宮）",
            phoneNumber: "0596-24-1111（神宮司庁）",
            address: "三重県度会郡大紀町滝原872",
            receptionInfo:
                "宿衛屋 300円\n6:00～18:00（1,2,3,4,9月）\n6:00～19:00（5,6,7,8月）\n6:00～17:00（10,11,12月）",
            officialHp: "https://www.isejingu.or.jp/about/outerbetsugu/",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/UPmWa62wYSr6jKqGA",
            mapLink: "https://maps.app.goo.gl/sgUwfK3kV6dtApEK7",
            tripGuideLink: "https://taiki-okuise.jp/",
        },
        relativeJinjaList: ["瀧原宮"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000024",
        name: "名居",
        nameKana: "ない",
        lastWord: "神社",
        position: {
            latitude: 34.621564,
            longitude: 136.126839,
        },
        parkingPosition: {
            latitude: 34.6216389,
            longitude: 136.1273056,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "名居神社の「ない」は地震を意味する古語。推古天皇7年の大地震以降各地に祀（まつ）られた「ないの神」が社名として残るのは、延喜神名式の中で本社のみです。",
            phoneNumber: "0595-68-0214",
            address: "三重県名張市下比奈知2092",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "無し",
            parking: "https://maps.app.goo.gl/ygsTupHFBH3Dgi5u9",
            mapLink: "https://maps.app.goo.gl/o6zac4EUoKNW1T9H8",
            tripGuideLink: "https://www.kankou-nabari.jp/",
        },
        relativeJinjaList: ["宇流冨志禰神社", "名居神社", "蛭子神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000000",
        name: "倭姫宮",
        nameKana: "やまとひめのみや",
        lastWord: "",
        position: {
            latitude: 34.4851321,
            longitude: 136.7243415,
        },
        parkingPosition: {
            latitude: 34.4859083,
            longitude: 136.7238072,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "倭姫宮は、皇大神宮（内宮）の別宮で、倭姫命がご祭神としてお祀（まつ）りされています。内宮のご鎮座に携わられた大きなご功績をお慕いして、神宮と宇治山田市（現在の伊勢市）の方々の請願によって、大正12年（1923年）に倭姫宮は創建されました。神宮にある諸宮社のご由緒が極めて古い中で、とても新しいお宮であり、令和5年（2023年）には創建百年を迎えたところです。",
            phoneNumber: "0596-24-1111（神宮司庁）",
            address: "三重県伊勢市楠部町5",
            receptionInfo:
                "宿衛屋 300円\n6:00～18:00（1,2,3,4,9月）\n6:00～19:00（5,6,7,8月）\n6:00～17:00（10,11,12月）",
            officialHp: "https://www.isejingu.or.jp/about/outerbetsugu/",
            restRoom: "無し",
            parking: "https://maps.app.goo.gl/9du8LTvVVmF4EQ857",
            mapLink: "https://maps.app.goo.gl/nCuX6dmm4Fy9HGp28",
            tripGuideLink: "https://www.iseshima-kanko.jp/",
        },
        relativeJinjaList: ["倭姫宮"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000019",
        name: "神坐日向",
        nameKana: "みわにますむかい",
        lastWord: "神社",
        position: {
            latitude: 34.527032,
            longitude: 135.852826,
        },
        parkingPosition: {
            latitude: 34.5284707,
            longitude: 135.8462587,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "大神神社のご祭神大物主大神（おおものぬしのおおかみ）から大神の神主となられた大直禰子命（おおたたねこのみこと）の間の三代の神様がご祭神。平安時代の延喜式神名帳（えんぎしきじんみょうちょう）にも記される古社で、神社の古絵図に「御子宮（みこのみや）」として描かれています。社殿が神社建築では珍しく北向きです。",
            phoneNumber: "0744-42-6633",
            address: "奈良県桜井市三輪123",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "無し",
            parking: "無し（大神神社駐車場をご利用ください）",
            mapLink: "https://maps.app.goo.gl/kBJhNNjPwyhh3nEY9",
            tripGuideLink: "https://sakuraikanko.com/",
        },
        relativeJinjaList: ["神坐日向神社", "檜原神社", "大神神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000020",
        name: "劔主",
        nameKana: "つるぎぬ",
        lastWord: "神社",
        position: {
            latitude: 34.4566917,
            longitude: 135.9192384,
        },
        parkingPosition: {
            latitude: 34.4566917,
            longitude: 135.9192384,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "奈良県宇陀市大宇陀宮奥に鎮座する神社。下宮奥の剣主神社、半阪の剣主神社とともに式内剱主神社のうちの一社。江戸時代までは「白色大明神」と呼ばれていました。",
            phoneNumber: "0744-23-7695",
            address: "奈良県宇陀市大宇陀宮奥116",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "無し",
            parking: "無し",
            mapLink: "https://maps.app.goo.gl/xjk6QjwYkk5wh9bz8",
            tripGuideLink: "https://www.uda-kankou.jp/",
        },
        relativeJinjaList: ["阿紀神社", "劔主神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000026",
        name: "敢国",
        nameKana: "あえくに",
        lastWord: "神社",
        position: {
            latitude: 34.7873301,
            longitude: 136.1639583,
        },
        parkingPosition: {
            latitude: 34.7868677,
            longitude: 136.1621219,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "南宮山の麓に鎮座する敢國神社は、伊賀国の一宮とされ、創建は斉明天皇4年と伝えられています。現在は大彦命（おおひこのみこと）と少彦名命（すくなひこなのみこと）、金山比咩命（かなやまひめのみこと）の三神を祀（まつ）っています。伊賀地域に広く伝播する獅子神楽に大きな影響を与えた「敢国神社の獅子舞」は、県指定無形民俗文化財に指定されています。",
            phoneNumber: "0595-23-3061",
            address: "三重県伊賀市一之宮877",
            receptionInfo: "社務所（在社時のみ） 500円",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/fiNtet4uUm5AM4nX8",
            mapLink: "https://maps.app.goo.gl/RNVkdDichALQ5ew2A",
            tripGuideLink: "https://www.iga-guide.com/",
        },
        relativeJinjaList: ["都美恵神社", "敢国神社"],
        eventList: [
            {
                name: "黒党祭",
                details:
                    "伊賀忍者の頭領、服部一族の奇祭「黒党（くろんど）まつり」が執り行われます。平安時代末期から始まり、神事に携わる者は黒装束に身を固める慣わしです。平成7年（1995年）に、当時の宮司の熱意により、450年ぶりに復興されました。",
                month: 11,
                date: 23,
                time: "11:00-",
            },
            {
                name: "例祭（おんまつり）",
                details:
                    "地元で「御祭」を「おんまつり」と呼び親しんでいる祭りで、五穀豊穣・家内安全を祈願して奉納される「獅子神楽」で知られています。伊賀各地で行われる獅子神楽の原型とされています。",
                month: 12,
                date: 5,
                time: "",
            },
        ],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000033",
        name: "尾野",
        nameKana: "おの",
        lastWord: "神社",
        position: {
            latitude: 35.074381,
            longitude: 136.676995,
        },
        parkingPosition: {
            latitude: 35.074381,
            longitude: 136.676995,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "古代、周囲が海であったころ船着き場であったことから「船着大明神」を別名を持つ神社。明治時代には県社に格付けられ、倭姫命が4年間滞在したとされる「桑名野代宮」伝承地ともいわれています。",
            phoneNumber: "0594-21-1352",
            address: "三重県桑名市東方2194",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "無し",
            parking: "無し",
            mapLink: "https://maps.app.goo.gl/XmPaXszLxhNBNJ1v6",
            tripGuideLink: "https://www.city.kuwana.lg.jp/kanko/",
        },
        relativeJinjaList: ["神館神社", "尾野神社", "野志里神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000032",
        name: "野志里",
        nameKana: "のじり",
        lastWord: "神社",
        position: {
            latitude: 35.1184626,
            longitude: 136.6457475,
        },
        parkingPosition: {
            latitude: 35.1184626,
            longitude: 136.6457475,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "垂仁天皇の御代の創始とされ、倭姫命が御船に乗られて尾張中島宮からお移りになり、伊勢の國造でご巡幸の右腕となる大若子命（おおわかこのみこと）が参上し、大御神にご奉仕した。楠のご神木と、「伊勢神宮御旧跡野代の宮」の石碑もある。",
            phoneNumber: "無し",
            address: "三重県桑名市多度町下野代3073",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "有り",
            parking: "無し",
            mapLink: "https://maps.app.goo.gl/XHxu8xEsxZphgxRx6",
            tripGuideLink: "https://www.city.kuwana.lg.jp/kanko/",
        },
        relativeJinjaList: ["神館神社", "尾野神社", "野志里神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000034",
        name: "忍山",
        nameKana: "おしやま",
        lastWord: "神社",
        position: {
            latitude: 34.8549156,
            longitude: 136.4368558,
        },
        parkingPosition: {
            latitude: 34.8543333,
            longitude: 136.4368333,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "倭姫命が桑名野代宮から鈴鹿に來られた際、この忍山にて神宮を奉斎しました。日本武尊の妻である弟橘媛（おとたちばなひめ）の生誕地とされ、日本武尊が死した場所ともされています。",
            phoneNumber: "0595-83-3756",
            address: "三重県亀山市野村4-4-65",
            receptionInfo: "社務所（在社時のみ）",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/9i9Hpb7WEzb5F7uR7",
            mapLink: "https://maps.app.goo.gl/eD9YfVtRYM3iQBFb7",
            tripGuideLink: "http://kameyama-kanko.com/",
        },
        relativeJinjaList: ["布氣皇舘太神社", "忍山神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000029",
        name: "宇波刀",
        nameKana: "うはと",
        lastWord: "神社",
        position: {
            latitude: 35.343693,
            longitude: 136.684912,
        },
        parkingPosition: {
            latitude: 35.343693,
            longitude: 136.684912,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "垂仁天皇の御世（紀元前20年頃）に創建されたと伝えられる由緒あるお宮です。倭姫命が天照大神を奉じ美濃国に遷幸（せんこう）され、「伊久良河宮（いくらがわのみや）」に4年奉斎されたされたといわれています。",
            phoneNumber: "無し",
            address: "岐阜県安八郡安八町森部373-10",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "無し",
            parking: "無し",
            mapLink: "https://maps.app.goo.gl/sgHjTyssGt1PYsJh7",
            tripGuideLink: "https://www.town.anpachi.lg.jp/category/5-1-0-0-0-0-0-0-0-0.html",
        },
        relativeJinjaList: ["天神神社", "宇波刀神社", "名木林神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000030",
        name: "真清田",
        nameKana: "ますみだ",
        lastWord: "神社",
        position: {
            latitude: 35.307343,
            longitude: 136.802015,
        },
        parkingPosition: {
            latitude: 35.3076121,
            longitude: 136.8005954,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "御祭神は尾張開拓の祖である「天火明命（あめのほあかりのみこと）」。尾張国の一之宮として2600有余年の歴史を持っています。元々この地域は木曽川の灌漑（かんがい）用水による水田地帯として、清く澄んだ水によって水田を形成していたため、真清田（ますみだ）と名付けらました。",
            phoneNumber: "0586-73-5196",
            address: "愛知県一宮市真清田1-2-1",
            receptionInfo: "社務所 500円\n9:00〜16:00",
            officialHp: "https://www.masumida.or.jp/",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/wWmAEvpFuMnC9cBK7",
            mapLink: "https://maps.app.goo.gl/CUi7pUjfNV67VNZQA",
            tripGuideLink: "https://www.138ss.com/",
        },
        relativeJinjaList: ["酒見神社", "真清田神社", "御園神明社"],
        eventList: [
            {
                name: "一宮七夕まつり",
                details:
                    "服織神社の織物の神・萬幡豊秋津師比賣命に感謝し、一宮の主軸産業である機織工業の繁栄を願う祭り。市や商工会議所が中心となった「七夕まつり協進会」と真清田神社が一体となって開催し、毎年約130万人が見物に訪れます。土曜夕方には「御衣奉献行列」、金曜夜には「提灯まつり」が行われます。ちなみに、本来の七夕の起源は、技芸の上達を織女に祈って供え物をする中国伝来の「乞巧奠」と、日本固有の盆の禊が習合したものと考えられています。",
                month: 7,
                date: 25,
                time: "",
            },
            {
                name: "一宮七夕まつり",
                details:
                    "服織神社の織物の神・萬幡豊秋津師比賣命に感謝し、一宮の主軸産業である機織工業の繁栄を願う祭り。市や商工会議所が中心となった「七夕まつり協進会」と真清田神社が一体となって開催し、毎年約130万人が見物に訪れます。土曜夕方には「御衣奉献行列」、金曜夜には「提灯まつり」が行われます。ちなみに、本来の七夕の起源は、技芸の上達を織女に祈って供え物をする中国伝来の「乞巧奠」と、日本固有の盆の禊が習合したものと考えられています。",
                month: 7,
                date: 26,
                time: "",
            },
            {
                name: "一宮七夕まつり",
                details:
                    "服織神社の織物の神・萬幡豊秋津師比賣命に感謝し、一宮の主軸産業である機織工業の繁栄を願う祭り。市や商工会議所が中心となった「七夕まつり協進会」と真清田神社が一体となって開催し、毎年約130万人が見物に訪れます。土曜夕方には「御衣奉献行列」、金曜夜には「提灯まつり」が行われます。ちなみに、本来の七夕の起源は、技芸の上達を織女に祈って供え物をする中国伝来の「乞巧奠」と、日本固有の盆の禊が習合したものと考えられています。",
                month: 7,
                date: 27,
                time: "",
            },
            {
                name: "一宮七夕まつり",
                details:
                    "服織神社の織物の神・萬幡豊秋津師比賣命に感謝し、一宮の主軸産業である機織工業の繁栄を願う祭り。市や商工会議所が中心となった「七夕まつり協進会」と真清田神社が一体となって開催し、毎年約130万人が見物に訪れます。土曜夕方には「御衣奉献行列」、金曜夜には「提灯まつり」が行われます。ちなみに、本来の七夕の起源は、技芸の上達を織女に祈って供え物をする中国伝来の「乞巧奠」と、日本固有の盆の禊が習合したものと考えられています。",
                month: 7,
                date: 28,
                time: "",
            },
            {
                name: "夏越神事 輪くぐり",
                details:
                    "盛夏の頃、清流の傍に茂る葦（よし）を刈りとり、神事に用いる紙垂（しで）をとりつけ、祓麻（はらいま）と言われるお祓いの具を作ります。 これを用いて自らの左肩、右肩左肩を順にお祓いし最後に息を吹きかけ、茅で作った茅輪を潜り貫ける事により、その年の罪穢れを祓い落とします。茅には強い霊力が宿ると古くから伝えられています。",
                month: 8,
                date: 6,
                time: "18:00-",
            },
            {
                name: "観月祭",
                details:
                    "毎年中秋の名月に斎行しており、中秋の名月は旧暦8月15日の十五夜に当たります。",
                month: 9,
                date: 17,
                time: "18:00-",
            },
            {
                name: "御幣渡神事",
                details:
                    "10月15日の駒牽神事に先立って、前日の14日に御幣渡神事が執り行なわれます。これは、式馬の当番になっている各氏子区域の馬主に、馬の鞍に立てる金幣と口取の法被を渡す式です。駒牽神事の当日、式馬にこの金幣を授けて馳駆させます。",
                month: 10,
                date: 14,
                time: "14:00-",
            },
            {
                name: "太々神楽",
                details:
                    "春期の桃花祭に対する秋期の祭礼で、駒牽神事と共に毎年この日に行われます。神童神女6名・伶人の奉仕により7曲の神楽が御奉納されます。この太々神楽は、天明2年（1782年）9月から真清田神社で行なわれ、京都の神楽の家の「持明院家」と「船橋家」の紹介で伝えられたものです。",
                month: 10,
                date: 15,
                time: "10:00-",
            },
            {
                name: "駒牽神事",
                details:
                    "翌年執り行われる桃花祭に使用する役馬を定めんがためのものであり、馬の検閲を行います。",
                month: 10,
                date: 15,
                time: "14:00-",
            },
            {
                name: "神嘗祭",
                details: "午前7時から遥拝、午前9時より祝祭が執り行われます。",
                month: 10,
                date: 17,
                time: "07:00-",
            },
            {
                name: "七五三祭",
                details:
                    "御祭神は、尾張開拓の祖である「天火明命（あめのほあかりのみこと）」と申し、子孫繁栄の神様として、永きにわたり、お子さまの健やかなる成長を見守ってきました。人生の節目に、大切なお子さまのこれまでの成長に感謝し、これからの健やかな育成をご祈願します。",
                month: 11,
                date: 15,
                time: "09:00-",
            },
            {
                name: "大鏡餅奉納",
                details:
                    "農業奉賛会会員のご奉仕により搗き上げられた重量約300キロ、五俵分の巨大な大鏡餅が野菜船と共に真清田神社本殿に奉納されます。",
                month: 12,
                date: 29,
                time: "11:00-",
            },
            {
                name: "大祓",
                details:
                    "私たちが知らず知らずに犯してしまった罪、穢れを祓い清める神事で毎年6月30日と大晦日に行われます。\n紙を人の形に切り抜いた人形（ひとがた）で身体を撫で、息を吹きかけてお祓いをします。",
                month: 12,
                date: 31,
                time: "14:00-",
            },
            {
                name: "除夜祭",
                details: "",
                month: 12,
                date: 31,
                time: "15:00-",
            },
        ],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000037",
        name: "神戸神舘神明社",
        nameKana: "かんべこうたちしんめいしゃ",
        lastWord: "",
        position: {
            latitude: 34.553184,
            longitude: 136.5499021,
        },
        parkingPosition: {
            latitude: 34.553184,
            longitude: 136.5499021,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "神戸神館神明社は、旧社格は「村社」ともされ『延喜式神名帳』伊勢国飯高郡に載せる「意悲（おひ）神社」の論社です。天照大神を祀る地を求めて各地を巡った倭姫命が一時滞在した飯野高宮の地に比定される元伊勢の伝承地でもあります。",
            phoneNumber: "無し",
            address: "三重県松阪市下村町1791",
            receptionInfo: "御朱印有り（在社時のみ）",
            officialHp: "無し",
            restRoom: "無し",
            parking: "無し",
            mapLink: "https://maps.app.goo.gl/MX5FXfdRBMVyJo8o6",
            tripGuideLink: "https://www.matsusaka-kanko.com/",
        },
        relativeJinjaList: ["神山神社", "神戸神舘神明社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000039",
        name: "相鹿上",
        nameKana: "おおかがみ",
        lastWord: "神社",
        position: {
            latitude: 34.504258,
            longitude: 136.541813,
        },
        parkingPosition: {
            latitude: 34.5045833,
            longitude: 136.5420833,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "上代から平安時代にかけ同地を支配していた、大鹿首（おほかのおびと＝相可の地名の由来）が建立した神社。大鹿氏の祖神である天児屋根命（あめのこやねのみこと）が祀られています。相可地区は伊勢の神宮や斎宮の神領地になっており、政所や検校が置かれていました。",
            phoneNumber: "0598-38-3576",
            address: "三重県多気郡多気町相可464",
            receptionInfo: "御朱印有り（在社時のみ）",
            officialHp: "無し",
            restRoom: "無し",
            parking: "https://maps.app.goo.gl/DoSaowbHFnNEq7uXA",
            mapLink: "https://maps.app.goo.gl/i67nH2iKBu226Ufx5",
            tripGuideLink: "https://takichou-kanko.com/index.php",
        },
        relativeJinjaList: ["磯神社", "相鹿上神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000031",
        name: "御園神明社",
        nameKana: "みそのしんめいしゃ",
        lastWord: "",
        position: {
            latitude: 35.2216729,
            longitude: 136.8428589,
        },
        parkingPosition: {
            latitude: 35.2216729,
            longitude: 136.8428589,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "垂仁天皇の十六年、倭姫命が美濃の国伊久良河宮（いくらがわのみや）よりこの地に奉遷（ほうせん）し、3か月斎き奉ったといわれています。その後中島宮と称し、現在の御園神明社となったといわれています。",
            phoneNumber: "無し",
            address: "愛知県清須市一場734",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "無し",
            parking: "無し",
            mapLink: "https://maps.app.goo.gl/vNjLwFpsAE87ntxA6",
            tripGuideLink: "https://kiyosu-kanko.org/",
        },
        relativeJinjaList: ["酒見神社", "真清田神社", "御園神明社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000018",
        name: "神宮・皇大神宮",
        nameKana: "じんぐう･こうたいじんぐう",
        lastWord: "（内宮）",
        position: {
            latitude: 34.4562132,
            longitude: 136.7241542,
        },
        parkingPosition: {
            latitude: 34.4587772,
            longitude: 136.721637,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "「ここに鎮まり定まろう」と倭姫命にご神勅（しんちょく）があり、天照大御神がご鎮座された場所こそが、現代にも続く伊勢神宮の内宮です。皇女二代、九十年のご巡行を経てのことでした。",
            phoneNumber: "0596-24-1111（神宮司庁）",
            address: "三重県伊勢市宇治館町1",
            receptionInfo:
                "内宮神楽殿の授与所 300円\n6:00～18:00（1,2,3,4,9月）\n6:00～19:00（5,6,7,8月）\n6:00～17:00（10,11,12月）",
            officialHp: "https://www.isejingu.or.jp/",
            restRoom: "有り",
            parking: "（１時間以降有料）https://maps.app.goo.gl/5nTs61EdUZ1ytAuAA",
            mapLink: "https://maps.app.goo.gl/7BeYCNaWyuqw3o9CA",
            tripGuideLink: "https://www.iseshima-kanko.jp/",
        },
        relativeJinjaList: ["神宮・皇大神宮（内宮）"],
        eventList: [
            {
                name: "風日祈祭",
                details:
                    "農作物の成育期にあたり、天候が順調で風雨の災害もなく五穀の稔りが豊かであるよう、御幣を捧げてお祈りするお祭りです。豊受大神宮\n    は午前5時、皇大神宮は午前9時より執り行われます。",
                month: 8,
                date: 4,
                time: "",
            },
            {
                name: "神宮観月祭",
                details:
                    "中秋の名月を愛で、全国から応募のあった短歌と俳句の秀作を、神宮の楽師が古式により披講する雅な行事です。披講につづき、管絃と舞楽が奏行されます。",
                month: 9,
                date: 17,
                time: "",
            },
            {
                name: "秋の神楽祭",
                details:
                    "神恩に感謝を捧げ、国民の平和を祈って行われる行事で、舞楽や神苑の神賑行事が拝観できます。初日には外宮が午前8時、内宮が午前10時からそれぞれの神楽殿において御神楽を奉奏し、午前11時と午前2時には、内宮神苑の特設舞台で舞楽を公開します。また、この間、全国各地の名流名家の奉納行事があります。\n    但し、雨天時の公開舞楽は参集殿奉納舞台で午前11時のみです。",
                month: 9,
                date: 21,
                time: "",
            },
            {
                name: "秋の神楽祭",
                details:
                    "神恩に感謝を捧げ、国民の平和を祈って行われる行事で、舞楽や神苑の神賑行事が拝観できます。初日には外宮が午前8時、内宮が午前10時からそれぞれの神楽殿において御神楽を奉奏し、午前11時と午前2時には、内宮神苑の特設舞台で舞楽を公開します。また、この間、全国各地の名流名家の奉納行事があります。\n但し、雨天時の公開舞楽は参集殿奉納舞台で午前11時のみです。",
                month: 9,
                date: 22,
                time: "",
            },
            {
                name: "秋の神楽祭",
                details:
                    "神恩に感謝を捧げ、国民の平和を祈って行われる行事で、舞楽や神苑の神賑行事が拝観できます。初日には外宮が午前8時、内宮が午前10時からそれぞれの神楽殿において御神楽を奉奏し、午前11時と午前2時には、内宮神苑の特設舞台で舞楽を公開します。また、この間、全国各地の名流名家の奉納行事があります。\n但し、雨天時の公開舞楽は参集殿奉納舞台で午前11時のみです。",
                month: 9,
                date: 23,
                time: "",
            },
            {
                name: "神宮茶室の公開",
                details:
                    "神楽祭に合わせ神宮茶室を庭上から一般に公開します。但し、雨天の場合は中止となります。",
                month: 9,
                date: 21,
                time: "",
            },
            {
                name: "神宮茶室の公開",
                details:
                    "神楽祭に合わせ神宮茶室を庭上から一般に公開します。但し、雨天の場合は中止となります。",
                month: 9,
                date: 22,
                time: "",
            },
            {
                name: "神宮茶室の公開",
                details:
                    "神楽祭に合わせ神宮茶室を庭上から一般に公開します。但し、雨天の場合は中止となります。",
                month: 9,
                date: 23,
                time: "",
            },
            {
                name: "秋季皇霊祭遙拝",
                details:
                    "歴代天皇・皇后・皇親等の御霊をお祀りする皇居内の皇霊殿において、天皇陛下御親ら秋季皇霊祭を執り行わせられるのにあわせ、神宮では大宮司以下の神職が遙拝式を行います。",
                month: 9,
                date: 22,
                time: "08:00-",
            },
            {
                name: "御酒殿祭",
                details:
                    "内宮神楽殿の東側に隣接する御酒殿において、神嘗祭の由貴大御饌にお供えする御料酒（白酒・黒酒・醴酒・清酒）がうるわしく醸成できるよう祈願し、あわせて全国の酒造業の繁栄をお祈りします。",
                month: 10,
                date: 1,
                time: "10:00-",
            },
            {
                name: "神御衣奉織始祭",
                details:
                    "10月14日の神御衣祭に奉る和妙（神服織機殿神社）・荒妙（神麻続機殿神社）の神御衣が清く美しく織り上がるようにお祈りをささげ、地元有志の奉仕によって清浄のうちに織り立てが行われます。",
                month: 10,
                date: 1,
                time: "08:00-",
            },
            {
                name: "御塩殿祭",
                details:
                    "神嘗祭を始め、全ての祭典にお供えする堅塩を焼き固める際のお祭りで、あわせて全国の塩業の繁栄をお祈りします。5日から9日までの5日間、御塩殿で荒塩を三角錐形の土器につめて焼き固めます。",
                month: 10,
                date: 5,
                time: "10:00-",
            },
            {
                name: "神御衣奉織鎮謝祭",
                details:
                    "和妙（神服織機殿神社）・荒妙（神麻続機殿神社）の神御衣が美しく織り上がったことに感謝をささげる鎮謝のお祭りです。織り上がった和妙と荒妙は内宮に運ばれ、10月14日の神御衣祭で奉られます。",
                month: 10,
                date: 13,
                time: "08:00-",
            },
            {
                name: "神御衣祭",
                details:
                    "皇大神宮と別宮荒祭宮の大御前に神服織機殿神社、神麻続機殿神社で古式のままに奉織された和妙と荒妙を、高天原の古事に因んで奉るお祭りです。",
                month: 10,
                date: 14,
                time: "12:00-",
            },
            {
                name: "御卜",
                details:
                    "神嘗祭奉仕の神職が、奉仕直前に神の御心にかなうかどうかをおうかがいする行事です。皇大神宮にて執り行われます。",
                month: 10,
                date: 15,
                time: "17:00-",
            },
            {
                name: "初穂曳（外宮）",
                details:
                    "神嘗祭を奉祝して、伊勢市民が奉曳車を仕立て、ハッピ姿に木遣歌も勇ましく、外宮に初穂を搬入します。",
                month: 10,
                date: 15,
                time: "",
            },
            {
                name: "初穂曳（内宮）",
                details:
                    "神嘗祭を奉祝して、伊勢市民が初穂舟を仕立て、ハッピ姿に木遣歌も勇ましく五十鈴川をさかのぼり、内宮に初穂を搬入します。",
                month: 10,
                date: 16,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 15,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 16,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 17,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 18,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 19,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 20,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 21,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 22,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 23,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 24,
                time: "",
            },
            {
                name: "神嘗祭",
                details:
                    "神嘗祭は、6月・12月の月次祭と共に「三節祭」と呼ばれ、神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が行われ、明くる正午には、勅使をお迎えして奉幣の儀を奉仕します。お祭りでは秋の実りに感謝申し上げ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。期間中は別宮を始め、摂社・末社・所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 10,
                date: 25,
                time: "",
            },
            {
                name: "倭姫宮秋の例大祭",
                details:
                    "倭姫宮御杖代奉賛会主催の神恩感謝の例大祭が開催されます。当日は奉賛行事等もあり、終日賑わいを呈します。",
                month: 11,
                date: 5,
                time: "",
            },
            {
                name: "新嘗祭",
                details:
                    "宮中の神嘉殿において、天皇陛下御親ら新嘗祭を執り行わせられるのに際し、神宮へ勅使を御差遣になり幣帛が奉られます。\n神宮では天照大御神をはじめとする神々に神饌をお供えする大御饌の儀が行われ、続いて勅使が天皇陛下の幣帛を奉る奉幣の儀が行われます。新嘗祭は両正宮に引き続き11月29日まで、すべての宮社で執り行われます。詳細日程は公式HPをご確認ください。",
                month: 11,
                date: 23,
                time: "",
            },
            {
                name: "新嘗祭",
                details:
                    "宮中の神嘉殿において、天皇陛下御親ら新嘗祭を執り行わせられるのに際し、神宮へ勅使を御差遣になり幣帛が奉られます。\n神宮では天照大御神をはじめとする神々に神饌をお供えする大御饌の儀が行われ、続いて勅使が天皇陛下の幣帛を奉る奉幣の儀が行われます。新嘗祭は両正宮に引き続き11月29日まで、すべての宮社で執り行われます。詳細日程は公式HPをご確認ください。",
                month: 11,
                date: 24,
                time: "",
            },
            {
                name: "新嘗祭",
                details:
                    "宮中の神嘉殿において、天皇陛下御親ら新嘗祭を執り行わせられるのに際し、神宮へ勅使を御差遣になり幣帛が奉られます。\n神宮では天照大御神をはじめとする神々に神饌をお供えする大御饌の儀が行われ、続いて勅使が天皇陛下の幣帛を奉る奉幣の儀が行われます。新嘗祭は両正宮に引き続き11月29日まで、すべての宮社で執り行われます。詳細日程は公式HPをご確認ください。",
                month: 11,
                date: 25,
                time: "",
            },
            {
                name: "新嘗祭",
                details:
                    "宮中の神嘉殿において、天皇陛下御親ら新嘗祭を執り行わせられるのに際し、神宮へ勅使を御差遣になり幣帛が奉られます。\n神宮では天照大御神をはじめとする神々に神饌をお供えする大御饌の儀が行われ、続いて勅使が天皇陛下の幣帛を奉る奉幣の儀が行われます。新嘗祭は両正宮に引き続き11月29日まで、すべての宮社で執り行われます。詳細日程は公式HPをご確認ください。",
                month: 11,
                date: 26,
                time: "",
            },
            {
                name: "新嘗祭",
                details:
                    "宮中の神嘉殿において、天皇陛下御親ら新嘗祭を執り行わせられるのに際し、神宮へ勅使を御差遣になり幣帛が奉られます。\n神宮では天照大御神をはじめとする神々に神饌をお供えする大御饌の儀が行われ、続いて勅使が天皇陛下の幣帛を奉る奉幣の儀が行われます。新嘗祭は両正宮に引き続き11月29日まで、すべての宮社で執り行われます。詳細日程は公式HPをご確認ください。",
                month: 11,
                date: 27,
                time: "",
            },
            {
                name: "新嘗祭",
                details:
                    "宮中の神嘉殿において、天皇陛下御親ら新嘗祭を執り行わせられるのに際し、神宮へ勅使を御差遣になり幣帛が奉られます。\n神宮では天照大御神をはじめとする神々に神饌をお供えする大御饌の儀が行われ、続いて勅使が天皇陛下の幣帛を奉る奉幣の儀が行われます。新嘗祭は両正宮に引き続き11月29日まで、すべての宮社で執り行われます。詳細日程は公式HPをご確認ください。",
                month: 11,
                date: 28,
                time: "",
            },
            {
                name: "新嘗祭",
                details:
                    "宮中の神嘉殿において、天皇陛下御親ら新嘗祭を執り行わせられるのに際し、神宮へ勅使を御差遣になり幣帛が奉られます。\n神宮では天照大御神をはじめとする神々に神饌をお供えする大御饌の儀が行われ、続いて勅使が天皇陛下の幣帛を奉る奉幣の儀が行われます。新嘗祭は両正宮に引き続き11月29日まで、すべての宮社で執り行われます。詳細日程は公式HPをご確認ください。",
                month: 11,
                date: 29,
                time: "",
            },
            {
                name: "御酒殿祭",
                details:
                    "内宮神楽殿の東側に隣接する御酒殿において、12月の月次祭の由貴大御饌にお供えする御料酒（白酒・黒酒・醴酒・清酒）がうるわしく醸成できるよう祈願し、あわせて全国酒造業の繁栄をお祈りします。",
                month: 12,
                date: 1,
                time: "10:00-",
            },
            {
                name: "興玉神祭",
                details:
                    "月次祭の奉仕にあたり、御卜に先立ち内宮御垣内西北隅に鎮座する地主の神、興玉神をお祀りします。",
                month: 12,
                date: 15,
                time: "",
            },
            {
                name: "御卜",
                details:
                    "月次祭奉仕の神職が、奉仕直前に神の御心にかなうかどうかをおうかがいする行事です。",
                month: 12,
                date: 15,
                time: "16:00-",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 15,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 16,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 17,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 18,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 19,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 20,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 21,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 22,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 23,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 24,
                time: "",
            },
            {
                name: "月次祭",
                details:
                    "10月の神嘗祭・6月の月次祭と共に「三節祭」と呼ばれる神宮の最も由緒深い祭典です。浄闇の中、二度にわたって由貴大御饌の儀が、明くる正午には奉幣の儀が行われ、皇室の弥栄、五穀の豊穣、国家の隆昌、並びに国民の平安を祈願します。祭典は、両正宮に引き続き12月25日まで、別宮をはじめ摂社、末社、所管社に至るすべてのお社において行われます。詳細日程は公式HPをご確認ください。",
                month: 12,
                date: 25,
                time: "",
            },
        ],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000021",
        name: "葛",
        nameKana: "くず",
        lastWord: "神社",
        position: {
            latitude: 34.5530175,
            longitude: 135.9854939,
        },
        parkingPosition: {
            latitude: 34.5472941,
            longitude: 135.9829392,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "創建は不詳。第11代垂仁天皇が290年（垂仁25年）春、天照大神を当地篠幡へ遷座（せんざ）する際、豊鍬入姫命（とよすきいりひめのみこと）、倭姫命の二柱を近隣の篠畑神社の摂社として当社に祀（まつ）った。現祭神は天照大神が祀られている。",
            phoneNumber: "無し",
            address: "奈良県宇陀市榛原山辺三1145-1",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "無し",
            parking: "https://maps.app.goo.gl/EQoPqJKfhzmAYLvq6",
            mapLink: "https://maps.app.goo.gl/RaLZpHcsrN77L5n69",
            tripGuideLink: "https://www.uda-kankou.jp/",
        },
        relativeJinjaList: ["篠畑神社", "葛神社", "御杖神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000022",
        name: "御杖",
        nameKana: "みつえ",
        lastWord: "神社",
        position: {
            latitude: 34.4941971,
            longitude: 136.1946225,
        },
        parkingPosition: {
            latitude: 34.4941578,
            longitude: 136.1940796,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "御杖神社は倭姫命が、天照大御神を奉じる候補地を求め、御杖村を訪れた際、その印として自らの「杖」を残したとされる伝承の地です。現在もその「杖」をお祀（まつ）りしています。",
            phoneNumber: "0745-95-2135",
            address: "奈良県宇陀郡御杖村神末1020",
            receptionInfo: "書き置き 200円",
            officialHp: "無し",
            restRoom: "無し",
            parking: "https://maps.app.goo.gl/Fdmm5LC7rn99mLB56",
            mapLink: "https://maps.app.goo.gl/yCrmVLedVqXSiXeP8",
            tripGuideLink: "https://www.uda-kankou.jp/",
        },
        relativeJinjaList: ["篠畑神社", "葛神社", "御杖神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000023",
        name: "蛭子",
        nameKana: "えびす",
        lastWord: "神社",
        position: {
            latitude: 34.618615,
            longitude: 136.089571,
        },
        parkingPosition: {
            latitude: 34.618615,
            longitude: 136.089571,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "隠市守宮（なばりいちもりのみや）と呼ばれ、御祭神は蛭子（えびす）神こと八重事代主命（やえことしろぬしのかみこと）。古来より「市守宮（いちきのみや）」とよばれた。名張の市場の地であった。",
            phoneNumber: "0595-64-1000",
            address: "三重県名張市鍛冶町98-97",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "無し",
            parking: "無し",
            mapLink: "https://maps.app.goo.gl/dS5uKD9Pvft9KzSc9",
            tripGuideLink: "https://www.kankou-nabari.jp/",
        },
        relativeJinjaList: ["宇流冨志禰神社", "名居神社", "蛭子神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000040",
        name: "檜原",
        nameKana: "ひばら",
        lastWord: "神社",
        position: {
            latitude: 34.5382884,
            longitude: 135.8547824,
        },
        parkingPosition: {
            latitude: 34.5384165,
            longitude: 135.8534779,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "崇神天皇の時代に、宮中より天照大御神を豊鍬入姫命（とよすきいりひめのみこと）に託されてお遷（うつ）しになり、「磯城神籬（しきひもろぎ）」を立て、お祀りされた「倭笠縫邑（やまとかさぬいのむら）」とされます。その後、豊鍬入姫命（とよすきいりひめのみこと）の大御神を、丹波国（京都）、大和国、木乃国（和歌山）、吉備国（岡山）とご巡幸されたあと、姪の倭姫命に、御杖代を託されました。ご遷幸（せんこう）の後も、その御蹟（みあと）を尊崇し、檜原神社として、引き続きお祀りし「元伊勢（もといせ）」と今に伝えられています。境内には、豊鍬入姫命を祀る豊鍬入姫宮（とよすきいりひめのみや）も鎮斎されています。",
            phoneNumber: "0744-45-2173",
            address: "奈良県桜井市三輪1422",
            receptionInfo: "社務所 300円\n9:00〜16:00ごろ",
            officialHp: "https://oomiwa.or.jp/keidaimap/23-hibarajinja/",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/myu7qRik77xMG3wq8",
            mapLink: "https://maps.app.goo.gl/me5c6XjbabTr1ucq7",
            tripGuideLink: "https://sakuraikanko.com/",
        },
        relativeJinjaList: ["神坐日向神社", "檜原神社", "大神神社"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000001",
        name: "大神",
        nameKana: "おおみわ",
        lastWord: "神社",
        position: {
            latitude: 34.528779,
            longitude: 135.852973,
        },
        parkingPosition: {
            latitude: 34.5284707,
            longitude: 135.8462587,
        },
        redSealAlbumPublish: true,
        jinjaInfo: {
            detailInfo:
                "三輪山を御神体と仰ぐ神社で「日本で一番古いお社」ともいわれています。倭姫命はこの三輪山の嶺にあったお宮で、叔母の豊鍬入姫命（とよすきいりひめのみこと）から御杖代（みつえしろ）の務めを託されたとされています。",
            phoneNumber: "0744-42-6633",
            address: "奈良県桜井市三輪1422",
            receptionInfo: "授与所 300円\n9:00〜17:00（下記以外）\n9:00〜16:30（12,1,2月）",
            officialHp: "https://oomiwa.or.jp/",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/9bFdjwG7ncvdfXYr5",
            mapLink: "https://maps.app.goo.gl/S8rU8hB58CtvbJuc9",
            tripGuideLink: "https://sakuraikanko.com/",
        },
        relativeJinjaList: ["神坐日向神社", "檜原神社", "大神神社"],
        eventList: [
            {
                name: "綱越神社例祭宵宮祭",
                details:
                    "暑い夏を無病息災に過ごすためのお祓いの祭りが摂社である綱越神社で行われます。",
                month: 7,
                date: 30,
                time: "16:00-",
            },
            {
                name: "綱越神社例祭（おんぱら祭）",
                details:
                    "祭典名の「おんぱら祭」は「御祓い」の転訛（てんか）です。祭典では古式にのっとり神馬引きや茅の輪くぐりが行われます。摂社である綱越神社で行われ、親しみを込めて「おんぱらさん」と呼ばれています。",
                month: 7,
                date: 31,
                time: "10:00-",
            },
            {
                name: "おんぱら祭奉納花火大会",
                details:
                    "おんぱら祭を締めくくる奉納花火大会が大神神社大鳥居近くの芝運動公園周辺にて行われ、例年2000発の花火が華やかに夜空を焦がします。",
                month: 7,
                date: 31,
                time: "19:30-",
            },
            {
                name: "七夕祭",
                details:
                    "一月遅れの8月7日に七夕の祭りが行われます。拝殿前に笹竹が立てられ、短冊に自由に願い事を書くことができます。",
                month: 8,
                date: 7,
                time: "14:00-",
            },
            {
                name: "卯の日祭",
                details:
                    "崇神（すじん）天皇が卯の日に大神祭（おおみわのまつり）を始められて以来、大神祭が「卯の日神事」と呼ばれるほどに卯の日がご神縁の日であり、その干支の日を大切にして毎月行われるものです。",
                month: 8,
                date: 19,
                time: "10:00-",
            },
            {
                name: "久延彦神社例祭",
                details:
                    "ご祭神の久延毘古命（くえびこのみこと）は「古事記」に世の事を尽くに知る知恵の神様として記されています。大神神社の末社である久延彦神社にて執り行われ、学業向上・入試合格はもとより、気力を養う身体の健康もお祈りします。",
                month: 9,
                date: 1,
                time: "11:30-",
            },
            {
                name: "卯の日祭",
                details:
                    "崇神（すじん）天皇が卯の日に大神祭（おおみわのまつり）を始められて以来、大神祭が「卯の日神事」と呼ばれるほどに卯の日がご神縁の日であり、その干支の日を大切にして毎月行われるものです。",
                month: 9,
                date: 12,
                time: "10:00-",
            },
            {
                name: "観月祭",
                details: "中秋の日に名月を愛でて祭典が行われます。祈祷殿前にて執り行われます。",
                month: 9,
                date: 17,
                time: "18:30-",
            },
            {
                name: "秋季皇霊祭遙拝",
                details:
                    "国民の祝日「秋分の日」に合わせ祖先のお御霊に感謝し故人をしのぶ祭典です。祈祷殿前にて執り行われます。",
                month: 9,
                date: 22,
                time: "10:00-",
            },
            {
                name: "玉列神社例祭",
                details:
                    "桜井市慈恩寺にある摂社で、氏神様ののどかな秋祭りと呼ぶにふさわしい祭りです。",
                month: 10,
                date: 12,
                time: "10:30-",
            },
            {
                name: "神嘗祭遙拝",
                details: "伊勢の神宮で神嘗祭が行われるにあたり、宮司以下神職が神宮を遥拝します。",
                month: 10,
                date: 17,
                time: "10:00-",
            },
            {
                name: "卯の日祭",
                details:
                    "崇神（すじん）天皇が卯の日に大神祭（おおみわのまつり）を始められて以来、大神祭が「卯の日神事」と呼ばれるほどに卯の日がご神縁の日であり、その干支の日を大切にして毎月行われるものです。",
                month: 10,
                date: 18,
                time: "10:00-",
            },
            {
                name: "抜穂祭",
                details:
                    "秋の収穫を感謝し、神饌田にて抜穂の神事が執り行われます。収穫されたお米は神社の祭典に供えられ、稲藁は新年の注連縄に用いられます。大美和の社にて執り行われます。",
                month: 10,
                date: 20,
                time: "10:00-",
            },
            {
                name: "秋の大神祭宵宮祭",
                details:
                    "春の大神祭同様に二千年の伝統を持つ祭典が三日間に渡り執り行われます。明日の大神祭が無事に斎行されることを宵宮に祈ります。",
                month: 10,
                date: 23,
                time: "15:00-",
            },
            {
                name: "秋の大神祭",
                details:
                    "大祭式で祭典が行われ、神楽「うま酒みわの舞」が四人の巫女により奉奏、秋の収穫を感謝すると共に氏子の安全を祈ります。",
                month: 10,
                date: 24,
                time: "10:00-",
            },
            {
                name: "秋の大神祭後宴祭",
                details:
                    "秋の大神祭が無事に執り行われたことを神前に感謝し、三日間の祭りが目出度く納められます。",
                month: 10,
                date: 25,
                time: "10:00-",
            },
            {
                name: "献詠祭",
                details:
                    "一般・学生から広く短歌を募集し、優秀作を選考する「三輪山まほろば短歌賞」の表彰式の前に神前に応募作品を奉奠し、選者や優秀作の短歌を披講する祭典が執り行われます。",
                month: 11,
                date: 3,
                time: "13:00-",
            },
            {
                name: "豊鍬入姫宮例祭",
                details:
                    "大神神社の摂社である檜原神社にて、豊鍬入姫宮（とよすきいりひめのみや）を祀る例祭が執り行われます。",
                month: 11,
                date: 5,
                time: "11:00-",
            },
            {
                name: "卯の日祭",
                details:
                    "崇神（すじん）天皇が卯の日に大神祭（おおみわのまつり）を始められて以来、大神祭が「卯の日神事」と呼ばれるほどに卯の日がご神縁の日であり、その干支の日を大切にして毎月行われるものです。",
                month: 11,
                date: 11,
                time: "10:00-",
            },
            {
                name: "崇神天皇奉讃祭",
                details:
                    "ご祭神の聖徳を称え崇神天皇奉讃祭（すじんてんのうほうさんさい）が斎行され、神楽「磯城の舞（しきのまい）」が特別に奉奏されます。天皇社で執り行われます。",
                month: 11,
                date: 14,
                time: "09:30-",
            },
            {
                name: "醸造安全祈願祭（酒まつり）",
                details:
                    "全国の酒造家や杜氏が参列し、新酒の醸造安全を祈ります。四人の巫女が杉を手に神楽「うま酒みわの舞｣を舞います。また、前日には酒の神のシンボルである拝殿の大杉玉が新しいものに取り替えられます。",
                month: 11,
                date: 14,
                time: "10:30-",
            },
            {
                name: "七五三詣生育安全祈願祭",
                details:
                    "11月の講社崇敬会月次祭にあわせて七五三詣の子供たちの生育安全を祈ります。この時期の社頭は晴れ着に着飾った七五三詣の子供達で賑やかです。",
                month: 11,
                date: 15,
                time: "10:00-",
            },
            {
                name: "新嘗祭 農林産物品評会",
                details:
                    "秋の実りを神様に感謝する祭典が大祭式で行われ、巫女が「磯城の舞（しきのまい）」を奉奏します。また、同時に奈良県下最大規模の農林産物品評会が開催され、祈祷殿前斎庭には立派な野菜や果物、花が並び、これらの品々の即売会も行われます。",
                month: 11,
                date: 23,
                time: "10:00-",
            },
            {
                name: "久延彦神社入試合格安全祈願祭",
                details:
                    "入学試験の時期にあたり、受験生の合格を知恵・学問の神様である久延毘古命に祈る祭典が行われます。大神神社の末社である久延彦神社にて執り行われます。",
                month: 12,
                date: 1,
                time: "11:30-",
            },
            {
                name: "献菓際",
                details:
                    "県内の菓子業者が参列し、銘菓がお供えされて菓子業界の発展と安全が祈られます。",
                month: 12,
                date: 3,
                time: "11:00-",
            },
            {
                name: "豊年講米初穂献納奉告祭",
                details:
                    "豊年講の農家の方々が真心こめて作られた新米が拝殿御棚にうず高く積まれて、献納の奉告の祭典が執り行わます。祭典にあわせて、先に行われた農林産物品評会の入賞者の表彰も行われます。",
                month: 12,
                date: 15,
                time: "10:00-",
            },
            {
                name: "年の終の卯の日祭",
                details:
                    "崇神（すじん）天皇が卯の日に大神祭（おおみわのまつり）を始められて以来、大神祭が「卯の日神事」と呼ばれるほどに卯の日がご神縁の日であり、その干支の日を大切にして毎月行われるものです。",
                month: 12,
                date: 17,
                time: "10:00-",
            },
            {
                name: "年越しの大祓",
                details:
                    "半年間の間に身についた罪や穢を人形に託して祓い、心身共に清々しく新年を迎えることを祈ります。",
                month: 12,
                date: 31,
                time: "14:00-",
            },
            {
                name: "除夜祭",
                details:
                    "一年間の最後に行われる祭典で、この年の息災を感謝し、うるわしく新年を迎えられることを祈ります。",
                month: 12,
                date: 31,
                time: "15:00-",
            },
        ],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000035",
        name: "阿射坂神社-小阿坂",
        nameKana: "あざか",
        lastWord: "",
        position: {
            latitude: 34.590789,
            longitude: 136.468753,
        },
        parkingPosition: {
            latitude: 34.5901111,
            longitude: 136.4688889,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "倭姫命のご巡幸の最大のピンチとなった荒ぶる神、伊豆速布留神（いつはやふるのかみ）を祭祀で鎮めたとされるのが、阿射加神社（大阿坂と小阿坂）主祭神の猿田彦神は伊勢国の国津神であり、この神を祭りる神社では最も由緒ある神社といわれています。小阿坂には、大若子命（おおわくごのみこと）を祭る摂社も。県道58号線参道入り口右側には、「猿田彦大神」の石碑が立っています。",
            phoneNumber: "無し",
            address: "三重県松阪市小阿坂町120",
            receptionInfo: "書き置き 300円",
            officialHp: "無し",
            restRoom: "有り",
            parking: "https://maps.app.goo.gl/sRjsgJZL1Vd6gsKSA",
            mapLink: "https://maps.app.goo.gl/BjYPJysDhSoGhjdT8",
            tripGuideLink: "https://www.matsusaka-kanko.com/",
        },
        relativeJinjaList: ["加良比乃神社", "阿射坂神社-小阿坂", "阿射坂神社-大阿坂"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000036",
        name: "阿射坂神社-大阿坂",
        nameKana: "あざか",
        lastWord: "",
        position: {
            latitude: 34.5983795,
            longitude: 136.4683841,
        },
        parkingPosition: {
            latitude: 34.5983795,
            longitude: 136.4683841,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "阿耶訶大神（あざかおおかみ）とは猿田彦神、伊豆速布留神（いつはやふるのかみ）、龍天大明神の三神で、主祭神は猿田彦神です。本殿の近くに龍天大明神の起源といわれる宮池があります。阿射加神社は、平安時代初期には県内でも数少ない延喜式内大社格に列せられた古社で、猿田彦命が祀られています。",
            phoneNumber: "無し",
            address: "三重県松阪市大阿坂町670",
            receptionInfo: "無し",
            officialHp: "無し",
            restRoom: "無し",
            parking: "無し",
            mapLink: "https://maps.app.goo.gl/GXXKTBcr7kUsbhnbA",
            tripGuideLink: "https://www.matsusaka-kanko.com/",
        },
        relativeJinjaList: ["加良比乃神社", "阿射坂神社-小阿坂", "阿射坂神社-大阿坂"],
        eventList: [],
    },
    {
        jinjaId: "00000000-0000-0000-0000-000000000028",
        name: "名木林",
        nameKana: "なぎばやし",
        lastWord: "神社",
        position: {
            latitude: 35.328905,
            longitude: 136.676722,
        },
        parkingPosition: {
            latitude: 35.328905,
            longitude: 136.676722,
        },
        redSealAlbumPublish: false,
        jinjaInfo: {
            detailInfo:
                "御祭神は天照大御神、豊受大神（とようけおおみかみ）。神社名「名木林」は古くは「なぎはや」と呼ばれていた。これは「水うちぎわ」を意味し、かつては近くまで海岸線があったと推定されています。",
            phoneNumber: "0584-68-1126",
            address: "岐阜県安八郡安八町大森1479-1",
            receptionInfo: "書き置き",
            officialHp: "無し",
            restRoom: "無し",
            parking: "無し",
            mapLink: "https://maps.app.goo.gl/VAPdXMEww4XR7BkA8",
            tripGuideLink: "https://www.town.anpachi.lg.jp/category/5-1-0-0-0-0-0-0-0-0.html",
        },
        relativeJinjaList: ["天神神社", "宇波刀神社", "名木林神社"],
        eventList: [],
    },
]
