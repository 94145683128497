// サービス終了のためテストは省略

import "./App.css"
import { useEffect } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { HomePage2025 } from "./pages/HomePage2025"
import { JinjaDetailPage2025 } from "./pages/JinjaDetailPage2025"

function App2025() {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const result = location.pathname.split("/").slice(0, 3).join("/")
        if (location.pathname !== "/app/home" && result !== "/app/jinja-detail") {
            navigate("/app/home")
        }
    }, [navigate, location.pathname])

    return (
        <Routes>
            <Route path="app/home" element={<HomePage2025 />} />
            <Route path="app/jinja-detail/:jinjaId" element={<JinjaDetailPage2025 />} />
            <Route path="*" element={<HomePage2025 />} />
        </Routes>
    )
}

export default App2025
