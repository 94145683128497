import jinja00 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000000.jpg"
import jinja01 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000001.jpg"
import jinja02 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000002.jpg"
import jinja03 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000003.jpg"
import jinja04 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000004.jpg"
import jinja05 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000005.jpg"
import jinja06 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000006.jpg"
import jinja07 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000007.jpg"
import jinja08 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000008.jpg"
import jinja09 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000009.jpg"
import jinja10 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000010.jpg"
import jinja11 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000011.jpg"
import jinja12 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000012.jpg"
import jinja13 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000013.jpg"
import jinja14 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000014.jpg"
import jinja15 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000015.jpg"
import jinja16 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000016.jpg"
import jinja17 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000017.jpg"
import jinja18 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000018.jpg"
import jinja19 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000019.jpg"
import jinja20 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000020.jpg"
import jinja21 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000021.jpg"
import jinja22 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000022.jpg"
import jinja23 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000023.jpg"
import jinja24 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000024.jpg"
import jinja25 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000025.jpg"
import jinja26 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000026.jpg"
import jinja27 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000027.jpg"
import jinja28 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000028.jpg"
import jinja29 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000029.jpg"
import jinja30 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000030.jpg"
import jinja31 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000031.jpg"
import jinja32 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000032.jpg"
import jinja33 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000033.jpg"
import jinja34 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000034.jpg"
import jinja35 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000035.jpg"
import jinja36 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000036.jpg"
import jinja37 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000037.jpg"
import jinja38 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000038.jpg"
import jinja39 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000039.jpg"
import jinja40 from "../../public/jinjaImage/00000000-0000-0000-0000-000000000040.jpg"

import jinja0picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000000/thumbnail/00000000-0000-0000-0000-000000000000.jpg"
import jinja0picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000000/thumbnail/00yamatohimenomiya.jpg"
import jinja0picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000000/thumbnail/00yamatohimenomiya3.jpg"
import jinja1picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000001/thumbnail/00000000-0000-0000-0000-000000000001.jpg"
import jinja1picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000001/thumbnail/01oomiwa2.jpg"
import jinja1picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000001/thumbnail/01oomiwa3.jpg"
import jinja2picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000002/thumbnail/00000000-0000-0000-0000-000000000002.jpg"
import jinja2picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000002/thumbnail/02aki2.jpg"
import jinja2picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000002/thumbnail/02aki3.jpg"
import jinja3picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000003/thumbnail/00000000-0000-0000-0000-000000000003.jpg"
import jinja3picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000003/thumbnail/03sasahata2.jpg"
import jinja3picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000003/thumbnail/03sasahata3.jpg"
import jinja4picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000004/thumbnail/00000000-0000-0000-0000-000000000004.jpg"
import jinja4picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000004/thumbnail/04urushihune2.jpg"
import jinja4picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000004/thumbnail/04urushihune3.jpg"
import jinja5picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000005/thumbnail/00000000-0000-0000-0000-000000000005.jpg"
import jinja5picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000005/thumbnail/05kanbe2.jpg"
import jinja5picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000005/thumbnail/05kanbe3.jpg"
import jinja6picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000006/thumbnail/00000000-0000-0000-0000-000000000006.jpg"
import jinja6picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000006/thumbnail/06tumie2.jpg"
import jinja6picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000006/thumbnail/06tumie3.jpg"
import jinja7picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000007/thumbnail/00000000-0000-0000-0000-000000000007.jpg"
import jinja7picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000007/thumbnail/07hikumo2.jpg"
import jinja7picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000007/thumbnail/07hikumo3.jpg"
import jinja8picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000008/thumbnail/00000000-0000-0000-0000-000000000008.jpg"
import jinja8picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000008/thumbnail/08sakatamyoujin2.jpg"
import jinja8picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000008/thumbnail/08sakatamyoujin3.jpg"
import jinja9picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000009/thumbnail/00000000-0000-0000-0000-000000000009.jpg"
import jinja9picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000009/thumbnail/09tenjin2.jpg"
import jinja9picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000009/thumbnail/09tenjin3.jpg"
import jinja10picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000010/thumbnail/00000000-0000-0000-0000-000000000010.jpg"
import jinja10picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000010/thumbnail/10sakami2.jpg"
import jinja10picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000010/thumbnail/10sakami3.jpg"
import jinja11picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000011/thumbnail/00000000-0000-0000-0000-000000000011.jpg"
import jinja11picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000011/thumbnail/11koudate2.jpg"
import jinja11picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000011/thumbnail/11koudate3.jpg"
import jinja12picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000012/thumbnail/00000000-0000-0000-0000-000000000012.jpg"
import jinja12picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000012/thumbnail/12hukekoutatudai2.jpg"
import jinja12picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000012/thumbnail/12hukekoutatudai3.jpg"
import jinja13picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000013/thumbnail/00000000-0000-0000-0000-000000000013.jpg"
import jinja13picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000013/thumbnail/13karahino2.jpg"
import jinja13picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000013/thumbnail/13karahino3.jpg"
import jinja14picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000014/thumbnail/00000000-0000-0000-0000-000000000014.jpg"
import jinja14picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000014/thumbnail/14kouyama2.jpg"
import jinja14picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000014/thumbnail/14kouyama3.jpg"
import jinja15picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000015/thumbnail/00000000-0000-0000-0000-000000000015.jpg"
import jinja15picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000015/thumbnail/15takesasahue2.jpg"
import jinja15picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000015/thumbnail/15takesasahue3.jpg"
import jinja16picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000016/thumbnail/00000000-0000-0000-0000-000000000016.jpg"
import jinja16picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000016/thumbnail/16iso2.jpg"
import jinja16picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000016/thumbnail/16iso3.jpg"
import jinja17picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000017/thumbnail/00000000-0000-0000-0000-000000000017.jpg"
import jinja17picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000017/thumbnail/17takiharanomiya2.jpg"
import jinja17picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000017/thumbnail/17takiharanomiya3.jpg"
import jinja18picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000018/thumbnail/00000000-0000-0000-0000-000000000018.jpg"
import jinja18picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000018/thumbnail/18naikaku2.jpg"
import jinja18picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000018/thumbnail/18naikaku3.jpg"
import jinja19picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000019/thumbnail/00000000-0000-0000-0000-000000000019.jpg"
import jinja19picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000019/thumbnail/1miwanimasumukai2.jpg"
import jinja19picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000019/thumbnail/1miwanimasumukai3.jpg"
import jinja20picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000020/thumbnail/00000000-0000-0000-0000-000000000020.jpg"
import jinja20picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000020/thumbnail/2tsuruginu2.jpg"
import jinja20picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000020/thumbnail/2tsuruginu3.jpg"
import jinja21picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000021/thumbnail/00000000-0000-0000-0000-000000000021.jpg"
import jinja21picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000021/thumbnail/3kuzu2.jpg"
import jinja21picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000021/thumbnail/3kuzu3.jpg"
import jinja22picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000022/thumbnail/00000000-0000-0000-0000-000000000022.jpg"
import jinja22picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000022/thumbnail/03mitue2.jpg"
import jinja22picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000022/thumbnail/mitsue3.jpg"
import jinja23picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000023/thumbnail/00000000-0000-0000-0000-000000000023.jpg"
import jinja23picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000023/thumbnail/04ebisu2.jpg"
import jinja23picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000023/thumbnail/04ebisu3.jpg"
import jinja24picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000024/thumbnail/00000000-0000-0000-0000-000000000024.jpg"
import jinja24picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000024/thumbnail/4nai2.jpg"
import jinja24picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000024/thumbnail/4nai3.jpg"
import jinja25picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000025/thumbnail/00000000-0000-0000-0000-000000000025.jpg"
import jinja25picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000025/thumbnail/5oomura2.jpg"
import jinja25picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000025/thumbnail/5oomura3.jpg"
import jinja26picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000026/thumbnail/00000000-0000-0000-0000-000000000026.jpg"
import jinja26picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000026/thumbnail/6aekuni2.jpg"
import jinja26picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000026/thumbnail/6aekuni3.jpg"
import jinja27picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000027/thumbnail/00000000-0000-0000-0000-000000000027.jpg"
import jinja27picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000027/thumbnail/7wakamiya2.jpg"
import jinja27picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000027/thumbnail/7wakamiya3.jpg"
import jinja28picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000028/thumbnail/00000000-0000-0000-0000-000000000028.jpg"
import jinja28picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000028/thumbnail/9nagibayashi2.jpg"
import jinja28picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000028/thumbnail/9nagibayashi3.jpg"
import jinja29picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000029/thumbnail/00000000-0000-0000-0000-000000000029.jpg"
import jinja29picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000029/thumbnail/9uhato2.jpg"
import jinja29picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000029/thumbnail/9uhato3.jpg"
import jinja30picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000030/thumbnail/00000000-0000-0000-0000-000000000030.jpg"
import jinja30picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000030/thumbnail/10masumida2.jpg"
import jinja30picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000030/thumbnail/10masumida3.jpg"
import jinja31picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000031/thumbnail/00000000-0000-0000-0000-000000000031.jpg"
import jinja31picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000031/thumbnail/10misonosinmeisha2.jpg"
import jinja31picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000031/thumbnail/10misonosinmeisha3.jpg"
import jinja32picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000032/thumbnail/00000000-0000-0000-0000-000000000032.jpg"
import jinja32picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000032/thumbnail/11noziri2.jpg"
import jinja32picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000032/thumbnail/11noziri3.jpg"
import jinja33picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000033/thumbnail/00000000-0000-0000-0000-000000000033.jpg"
import jinja33picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000033/thumbnail/11ono2.jpg"
import jinja33picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000033/thumbnail/11ono3.jpg"
import jinja34picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000034/thumbnail/00000000-0000-0000-0000-000000000034.jpg"
import jinja34picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000034/thumbnail/12oshiyama2.jpg"
import jinja34picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000034/thumbnail/12oshiyama3.jpg"
import jinja35picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000035/thumbnail/00000000-0000-0000-0000-000000000035.jpg"
import jinja35picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000035/thumbnail/13kozaka2.jpg"
import jinja35picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000035/thumbnail/13kozaka3.jpg"
import jinja36picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000036/thumbnail/00000000-0000-0000-0000-000000000036.jpg"
import jinja36picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000036/thumbnail/13oozaka2.jpg"
import jinja36picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000036/thumbnail/13oozaka3.jpg"
import jinja37picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000037/thumbnail/00000000-0000-0000-0000-000000000037.jpg"
import jinja37picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000037/thumbnail/14kanbekoutati2.jpg"
import jinja37picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000037/thumbnail/14kanbekoutati3.jpg"
import jinja38picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000038/thumbnail/00000000-0000-0000-0000-000000000038.jpg"
import jinja38picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000038/thumbnail/15takeooyodo1.jpg"
import jinja38picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000038/thumbnail/15takeooyodo2.jpg"
import jinja39picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000039/thumbnail/00000000-0000-0000-0000-000000000039.jpg"
import jinja39picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000039/thumbnail/16ookagami2.jpg"
import jinja39picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000039/thumbnail/16ookagami3.jpg"
import jinja40picture1 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000040/thumbnail/00000000-0000-0000-0000-000000000040.jpg"
import jinja40picture2 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000040/thumbnail/hibara2.jpg"
import jinja40picture3 from "../../public/jinjaImages/00000000-0000-0000-0000-000000000040/thumbnail/hibara3.jpg"

import jinja0picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000000/00000000-0000-0000-0000-000000000000.jpg"
import jinja0picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000000/00yamatohimenomiya2.jpg"
import jinja0picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000000/00yamatohimenomiya3.jpg"
import jinja1picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000001/00000000-0000-0000-0000-000000000001.jpg"
import jinja1picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000001/01oomiwa2.jpg"
import jinja1picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000001/01oomiwa3.jpg"
import jinja2picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000002/00000000-0000-0000-0000-000000000002.jpg"
import jinja2picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000002/02aki2.jpg"
import jinja2picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000002/02aki3.jpg"
import jinja3picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000003/00000000-0000-0000-0000-000000000003.jpg"
import jinja3picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000003/03sasahata2.jpg"
import jinja3picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000003/03sasahata3.jpg"
import jinja4picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000004/00000000-0000-0000-0000-000000000004.jpg"
import jinja4picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000004/04urushihune2.jpg"
import jinja4picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000004/04urushihune3.jpg"
import jinja5picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000005/00000000-0000-0000-0000-000000000005.jpg"
import jinja5picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000005/05kanbe2.jpg"
import jinja5picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000005/05kanbe3.jpg"
import jinja6picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000006/00000000-0000-0000-0000-000000000006.jpg"
import jinja6picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000006/06tumie2.jpg"
import jinja6picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000006/06tumie3.jpg"
import jinja7picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000007/00000000-0000-0000-0000-000000000007.jpg"
import jinja7picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000007/07hikumo2.jpg"
import jinja7picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000007/07hikumo3.jpg"
import jinja8picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000008/00000000-0000-0000-0000-000000000008.jpg"
import jinja8picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000008/08sakatamyoujin2.jpg"
import jinja8picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000008/08sakatamyoujin3.jpg"
import jinja9picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000009/00000000-0000-0000-0000-000000000009.jpg"
import jinja9picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000009/09tenjin2.jpg"
import jinja9picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000009/09tenjin3.jpg"
import jinja10picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000010/00000000-0000-0000-0000-000000000010.jpg"
import jinja10picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000010/10sakami2.jpg"
import jinja10picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000010/10sakami3.jpg"
import jinja11picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000011/00000000-0000-0000-0000-000000000011.jpg"
import jinja11picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000011/11koudate2.jpg"
import jinja11picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000011/11koudate3.jpg"
import jinja12picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000012/00000000-0000-0000-0000-000000000012.jpg"
import jinja12picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000012/12hukekoutatudai2.jpg"
import jinja12picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000012/12hukekoutatudai3.jpg"
import jinja13picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000013/00000000-0000-0000-0000-000000000013.jpg"
import jinja13picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000013/13karahino2.jpg"
import jinja13picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000013/13karahino3.jpg"
import jinja14picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000014/00000000-0000-0000-0000-000000000014.jpg"
import jinja14picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000014/14kouyama2.jpg"
import jinja14picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000014/14kouyama3.jpg"
import jinja15picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000015/00000000-0000-0000-0000-000000000015.jpg"
import jinja15picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000015/15takesasahue2.jpg"
import jinja15picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000015/15takesasahue3.jpg"
import jinja16picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000016/00000000-0000-0000-0000-000000000016.jpg"
import jinja16picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000016/16iso2.jpg"
import jinja16picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000016/16iso3.jpg"
import jinja17picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000017/00000000-0000-0000-0000-000000000017.jpg"
import jinja17picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000017/17takiharanomiya2.jpg"
import jinja17picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000017/17takiharanomiya3.jpg"
import jinja18picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000018/00000000-0000-0000-0000-000000000018.jpg"
import jinja18picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000018/18naikaku2.jpg"
import jinja18picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000018/18naikaku3.jpg"
import jinja19picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000019/00000000-0000-0000-0000-000000000019.jpg"
import jinja19picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000019/1miwanimasumukai2.jpg"
import jinja19picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000019/1miwanimasumukai3.jpg"
import jinja20picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000020/00000000-0000-0000-0000-000000000020.jpg"
import jinja20picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000020/2tsuruginu2.jpg"
import jinja20picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000020/2tsuruginu3.jpg"
import jinja21picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000021/00000000-0000-0000-0000-000000000021.jpg"
import jinja21picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000021/3kuzu2.jpg"
import jinja21picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000021/3kuzu3.jpg"
import jinja22picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000022/00000000-0000-0000-0000-000000000022.jpg"
import jinja22picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000022/03mitue2.jpg"
import jinja22picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000022/mitsue3.jpg"
import jinja23picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000023/00000000-0000-0000-0000-000000000023.jpg"
import jinja23picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000023/04ebisu2.jpg"
import jinja23picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000023/04ebisu3.jpg"
import jinja24picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000024/00000000-0000-0000-0000-000000000024.jpg"
import jinja24picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000024/4nai2.jpg"
import jinja24picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000024/4nai3.jpg"
import jinja25picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000025/00000000-0000-0000-0000-000000000025.jpg"
import jinja25picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000025/5oomura2.jpg"
import jinja25picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000025/5oomura3.jpg"
import jinja26picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000026/00000000-0000-0000-0000-000000000026.jpg"
import jinja26picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000026/6aekuni2.jpg"
import jinja26picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000026/6aekuni3.jpg"
import jinja27picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000027/00000000-0000-0000-0000-000000000027.jpg"
import jinja27picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000027/7wakamiya2.jpg"
import jinja27picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000027/7wakamiya3.jpg"
import jinja28picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000028/00000000-0000-0000-0000-000000000028.jpg"
import jinja28picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000028/9nagibayashi2.jpg"
import jinja28picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000028/9nagibayashi3.jpg"
import jinja29picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000029/00000000-0000-0000-0000-000000000029.jpg"
import jinja29picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000029/9uhato2.jpg"
import jinja29picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000029/9uhato3.jpg"
import jinja30picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000030/00000000-0000-0000-0000-000000000030.jpg"
import jinja30picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000030/10masumida2.jpg"
import jinja30picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000030/10masumida3.jpg"
import jinja31picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000031/00000000-0000-0000-0000-000000000031.jpg"
import jinja31picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000031/10misonosinmeisha2.jpg"
import jinja31picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000031/10misonosinmeisha3.jpg"
import jinja32picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000032/00000000-0000-0000-0000-000000000032.jpg"
import jinja32picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000032/11noziri2.jpg"
import jinja32picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000032/11noziri3.jpg"
import jinja33picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000033/00000000-0000-0000-0000-000000000033.jpg"
import jinja33picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000033/11ono2.jpg"
import jinja33picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000033/11ono3.jpg"
import jinja34picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000034/00000000-0000-0000-0000-000000000034.jpg"
import jinja34picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000034/12oshiyama2.jpg"
import jinja34picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000034/12oshiyama3.jpg"
import jinja35picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000035/00000000-0000-0000-0000-000000000035.jpg"
import jinja35picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000035/13kozaka2.jpg"
import jinja35picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000035/13kozaka3.jpg"
import jinja36picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000036/00000000-0000-0000-0000-000000000036.jpg"
import jinja36picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000036/13oozaka2.jpg"
import jinja36picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000036/13oozaka3.jpg"
import jinja37picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000037/00000000-0000-0000-0000-000000000037.jpg"
import jinja37picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000037/14kanbekoutati2.jpg"
import jinja37picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000037/14kanbekoutati3.jpg"
import jinja38picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000038/00000000-0000-0000-0000-000000000038.jpg"
import jinja38picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000038/15takeooyodo1.jpg"
import jinja38picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000038/15takeooyodo2.jpg"
import jinja39picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000039/00000000-0000-0000-0000-000000000039.jpg"
import jinja39picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000039/16ookagami2.jpg"
import jinja39picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000039/16ookagami3.jpg"
import jinja40picture1Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000040/00000000-0000-0000-0000-000000000040.jpg"
import jinja40picture2Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000040/hibara2.jpg"
import jinja40picture3Original from "../../public/jinjaImages/00000000-0000-0000-0000-000000000040/hibara3.jpg"

export const jinjaPictureList = [
    { jinjaPictures: [jinja0picture1, jinja0picture2, jinja0picture3] },
    { jinjaPictures: [jinja1picture1, jinja1picture2, jinja1picture3] },
    { jinjaPictures: [jinja2picture1, jinja2picture2, jinja2picture3] },
    { jinjaPictures: [jinja3picture1, jinja3picture2, jinja3picture3] },
    { jinjaPictures: [jinja4picture1, jinja4picture2, jinja4picture3] },
    { jinjaPictures: [jinja5picture1, jinja5picture2, jinja5picture3] },
    { jinjaPictures: [jinja6picture1, jinja6picture2, jinja6picture3] },
    { jinjaPictures: [jinja7picture1, jinja7picture2, jinja7picture3] },
    { jinjaPictures: [jinja8picture1, jinja8picture2, jinja8picture3] },
    { jinjaPictures: [jinja9picture1, jinja9picture2, jinja9picture3] },
    { jinjaPictures: [jinja10picture1, jinja10picture2, jinja10picture3] },
    { jinjaPictures: [jinja11picture1, jinja11picture2, jinja11picture3] },
    { jinjaPictures: [jinja12picture1, jinja12picture2, jinja12picture3] },
    { jinjaPictures: [jinja13picture1, jinja13picture2, jinja13picture3] },
    { jinjaPictures: [jinja14picture1, jinja14picture2, jinja14picture3] },
    { jinjaPictures: [jinja15picture1, jinja15picture2, jinja15picture3] },
    { jinjaPictures: [jinja16picture1, jinja16picture2, jinja16picture3] },
    { jinjaPictures: [jinja17picture1, jinja17picture2, jinja17picture3] },
    { jinjaPictures: [jinja18picture1, jinja18picture2, jinja18picture3] },
    { jinjaPictures: [jinja19picture1, jinja19picture2, jinja19picture3] },
    { jinjaPictures: [jinja20picture1, jinja20picture2, jinja20picture3] },
    { jinjaPictures: [jinja21picture1, jinja21picture2, jinja21picture3] },
    { jinjaPictures: [jinja22picture1, jinja22picture2, jinja22picture3] },
    { jinjaPictures: [jinja23picture1, jinja23picture2, jinja23picture3] },
    { jinjaPictures: [jinja24picture1, jinja24picture2, jinja24picture3] },
    { jinjaPictures: [jinja25picture1, jinja25picture2, jinja25picture3] },
    { jinjaPictures: [jinja26picture1, jinja26picture2, jinja26picture3] },
    { jinjaPictures: [jinja27picture1, jinja27picture2, jinja27picture3] },
    { jinjaPictures: [jinja28picture1, jinja28picture2, jinja28picture3] },
    { jinjaPictures: [jinja29picture1, jinja29picture2, jinja29picture3] },
    { jinjaPictures: [jinja30picture1, jinja30picture2, jinja30picture3] },
    { jinjaPictures: [jinja31picture1, jinja31picture2, jinja31picture3] },
    { jinjaPictures: [jinja32picture1, jinja32picture2, jinja32picture3] },
    { jinjaPictures: [jinja33picture1, jinja33picture2, jinja33picture3] },
    { jinjaPictures: [jinja34picture1, jinja34picture2, jinja34picture3] },
    { jinjaPictures: [jinja35picture1, jinja35picture2, jinja35picture3] },
    { jinjaPictures: [jinja36picture1, jinja36picture2, jinja36picture3] },
    { jinjaPictures: [jinja37picture1, jinja37picture2, jinja37picture3] },
    { jinjaPictures: [jinja38picture1, jinja38picture2, jinja38picture3] },
    { jinjaPictures: [jinja39picture1, jinja39picture2, jinja39picture3] },
    { jinjaPictures: [jinja40picture1, jinja40picture2, jinja40picture3] },
]

export const jinjaPictureOriginalList = [
    { jinjaPictures: [jinja0picture1Original, jinja0picture2Original, jinja0picture3Original] },
    { jinjaPictures: [jinja1picture1Original, jinja1picture2Original, jinja1picture3Original] },
    { jinjaPictures: [jinja2picture1Original, jinja2picture2Original, jinja2picture3Original] },
    { jinjaPictures: [jinja3picture1Original, jinja3picture2Original, jinja3picture3Original] },
    { jinjaPictures: [jinja4picture1Original, jinja4picture2Original, jinja4picture3Original] },
    { jinjaPictures: [jinja5picture1Original, jinja5picture2Original, jinja5picture3Original] },
    { jinjaPictures: [jinja6picture1Original, jinja6picture2Original, jinja6picture3Original] },
    { jinjaPictures: [jinja7picture1Original, jinja7picture2Original, jinja7picture3Original] },
    { jinjaPictures: [jinja8picture1Original, jinja8picture2Original, jinja8picture3Original] },
    { jinjaPictures: [jinja9picture1Original, jinja9picture2Original, jinja9picture3Original] },
    { jinjaPictures: [jinja10picture1Original, jinja10picture2Original, jinja10picture3Original] },
    { jinjaPictures: [jinja11picture1Original, jinja11picture2Original, jinja11picture3Original] },
    { jinjaPictures: [jinja12picture1Original, jinja12picture2Original, jinja12picture3Original] },
    { jinjaPictures: [jinja13picture1Original, jinja13picture2Original, jinja13picture3Original] },
    { jinjaPictures: [jinja14picture1Original, jinja14picture2Original, jinja14picture3Original] },
    { jinjaPictures: [jinja15picture1Original, jinja15picture2Original, jinja15picture3Original] },
    { jinjaPictures: [jinja16picture1Original, jinja16picture2Original, jinja16picture3Original] },
    { jinjaPictures: [jinja17picture1Original, jinja17picture2Original, jinja17picture3Original] },
    { jinjaPictures: [jinja18picture1Original, jinja18picture2Original, jinja18picture3Original] },
    { jinjaPictures: [jinja19picture1Original, jinja19picture2Original, jinja19picture3Original] },
    { jinjaPictures: [jinja20picture1Original, jinja20picture2Original, jinja20picture3Original] },
    { jinjaPictures: [jinja21picture1Original, jinja21picture2Original, jinja21picture3Original] },
    { jinjaPictures: [jinja22picture1Original, jinja22picture2Original, jinja22picture3Original] },
    { jinjaPictures: [jinja23picture1Original, jinja23picture2Original, jinja23picture3Original] },
    { jinjaPictures: [jinja24picture1Original, jinja24picture2Original, jinja24picture3Original] },
    { jinjaPictures: [jinja25picture1Original, jinja25picture2Original, jinja25picture3Original] },
    { jinjaPictures: [jinja26picture1Original, jinja26picture2Original, jinja26picture3Original] },
    { jinjaPictures: [jinja27picture1Original, jinja27picture2Original, jinja27picture3Original] },
    { jinjaPictures: [jinja28picture1Original, jinja28picture2Original, jinja28picture3Original] },
    { jinjaPictures: [jinja29picture1Original, jinja29picture2Original, jinja29picture3Original] },
    { jinjaPictures: [jinja30picture1Original, jinja30picture2Original, jinja30picture3Original] },
    { jinjaPictures: [jinja31picture1Original, jinja31picture2Original, jinja31picture3Original] },
    { jinjaPictures: [jinja32picture1Original, jinja32picture2Original, jinja32picture3Original] },
    { jinjaPictures: [jinja33picture1Original, jinja33picture2Original, jinja33picture3Original] },
    { jinjaPictures: [jinja34picture1Original, jinja34picture2Original, jinja34picture3Original] },
    { jinjaPictures: [jinja35picture1Original, jinja35picture2Original, jinja35picture3Original] },
    { jinjaPictures: [jinja36picture1Original, jinja36picture2Original, jinja36picture3Original] },
    { jinjaPictures: [jinja37picture1Original, jinja37picture2Original, jinja37picture3Original] },
    { jinjaPictures: [jinja38picture1Original, jinja38picture2Original, jinja38picture3Original] },
    { jinjaPictures: [jinja39picture1Original, jinja39picture2Original, jinja39picture3Original] },
    { jinjaPictures: [jinja40picture1Original, jinja40picture2Original, jinja40picture3Original] },
]

export const jinjaImageList = [
    jinja00,
    jinja01,
    jinja02,
    jinja03,
    jinja04,
    jinja05,
    jinja06,
    jinja07,
    jinja08,
    jinja09,
    jinja10,
    jinja11,
    jinja12,
    jinja13,
    jinja14,
    jinja15,
    jinja16,
    jinja17,
    jinja18,
    jinja19,
    jinja20,
    jinja21,
    jinja22,
    jinja23,
    jinja24,
    jinja25,
    jinja26,
    jinja27,
    jinja28,
    jinja29,
    jinja30,
    jinja31,
    jinja32,
    jinja33,
    jinja34,
    jinja35,
    jinja36,
    jinja37,
    jinja38,
    jinja39,
    jinja40,
]
