import { Wrapper } from "@googlemaps/react-wrapper"
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { JinjaMarker } from "./JinjaMarker"
import { Map } from "./Map"
import { ApplicationContext } from "../contexts/ApplicationContextProvider"
import { Jinja } from "@/repositories/jinjaRepository"
import { CurrentLocationMarker } from "@/components/CurrentLocationMarker"
import { JinjaNameMarker } from "@/components/JinjaNameMarker"
import { cloneDeep } from "lodash"
import currentPositionIcon from "@/assets/generalIcons/currentPositionIcon.svg"
import { noCurrentLocationPopup } from "@/functions/swalPopups/noCurrentLocationPopup"

type Props = {
    selectJinjaList: Jinja[]
    setSelectJinjaList: Dispatch<SetStateAction<Jinja[]>>
}

export const PaidGoogleMap = (props: Props) => {
    const [myPosition, setMyPosition] = useState({ latitude: 0, longitude: 0 })
    const { jinjaList, range, stateOfJinjaFilterForMap, histories, favoriteList } =
        useContext(ApplicationContext)!

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options)
    }, [])

    const successCallback = (position: { coords: { latitude: number; longitude: number } }) => {
        setMyPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
        })
    }

    const errorCallback = () => {
        noCurrentLocationPopup()
    }

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    }

    const filteredJinjaListFunc = () => {
        const newJinjaList = cloneDeep(jinjaList)
        const latRatioForKiroMeter = 111.319
        const lngRatioForKiroMeter = 90.429

        const filteredJinjaList =
            range === 8
                ? newJinjaList
                : newJinjaList.filter((jinja) => {
                      const latitudeDiff =
                          (jinja.position.latitude - myPosition.latitude) * latRatioForKiroMeter
                      const longitudeDiff =
                          (jinja.position.longitude - myPosition.longitude) * lngRatioForKiroMeter
                      const result = Math.floor(Math.sqrt(latitudeDiff ** 2 + longitudeDiff ** 2))
                      return result <= range * 10
                  })

        return filteredJinjaList.filter((jinja) => {
            if (stateOfJinjaFilterForMap[0] === true && !favoriteList.includes(jinja.jinjaId))
                return false
            if (stateOfJinjaFilterForMap[1] === true) {
                const jinjaIds = histories.map((history) => history.jinjaId)
                if (!jinjaIds.includes(jinja.jinjaId)) return false
            } else if (stateOfJinjaFilterForMap[1] === false) {
                const jinjaIds = histories.map((history) => history.jinjaId)
                if (jinjaIds.includes(jinja.jinjaId)) return false
            }
            if (stateOfJinjaFilterForMap[2] === true && jinja.jinjaInfo.receptionInfo === "無し")
                return false
            if (
                stateOfJinjaFilterForMap[2] === false &&
                !(jinja.jinjaInfo.receptionInfo === "無し")
            )
                return false
            if (stateOfJinjaFilterForMap[3] === true && !(jinja.jinjaInfo.restRoom === "有り"))
                return false
            if (stateOfJinjaFilterForMap[3] === false && !(jinja.jinjaInfo.restRoom === "無し"))
                return false
            if (stateOfJinjaFilterForMap[4] === true && jinja.jinjaInfo.parking.includes("無し"))
                return false
            return !(
                stateOfJinjaFilterForMap[4] === false && !jinja.jinjaInfo.parking.includes("無し")
            )
        })
    }

    const generateJinjaMarker = () => {
        return filteredJinjaListFunc().map((jinja, index) => {
            return (
                <JinjaMarker
                    key={`jinjaMarker${index}`}
                    selectJinjaList={props.selectJinjaList}
                    setSelectJinjaList={props.setSelectJinjaList}
                    jinja={jinja}
                    position={{ lat: jinja.position.latitude, lng: jinja.position.longitude }}
                />
            )
        })
    }

    const generateJinjaNameMarker = () => {
        return filteredJinjaListFunc().map((jinja, index) => {
            return (
                <JinjaNameMarker
                    key={`jinjaMarker${index}`}
                    jinja={jinja}
                    position={{ lat: jinja.position.latitude, lng: jinja.position.longitude }}
                />
            )
        })
    }

    return (
        <Wrapper
            apiKey={import.meta.env.VITE_GOOGLE_MAP_API_KEY}
            version="beta"
            libraries={["marker"]}
        >
            <Map>
                <CurrentLocationMarker
                    position={{ lat: myPosition.latitude, lng: myPosition.longitude }}
                    icon={currentPositionIcon}
                />
                {generateJinjaNameMarker()}
                {generateJinjaMarker()}
            </Map>
        </Wrapper>
    )
}
